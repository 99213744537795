$homepage-hero--min-height: 332px;
$homepage-hero--icon-font-size: 24px;
$homepage-hero--label-font-size: $t-a-typography-font-size-s;
$homepage-hero--label-font-size-tablet: $t-a-typography-font-size-md;
$homepage-hero--stat-label-font-size: $t-a-typography-font-size-s;
$homepage-hero--stat-label-font-size-tablet: $t-a-typography-font-size-s;
$homepage-hero--stat-number-font-size: $t-a-typography-font-size-s;
$homepage-hero--stat-number-font-size-tablet: $t-a-typography-font-size-xl;
$homepage-hero--dominant-background-color: #011d44;
$homepage-hero--focus-outline: $t-a-palette-default-blue-lighter;

.app-homepage-hero {
	position: relative;
	min-height: $homepage-hero--min-height;
}

.app-homepage-hero__image {
	background-color: $homepage-hero--dominant-background-color;
	height: 100%;
	position: absolute;
	z-index: -1;
	width: 100%;
	object-fit: cover;
	object-position: left;
}

.app-homepage-hero__container {
	@include u-container;
	padding-top: spacing(48);
	padding-bottom: spacing(48);
}


.app-homepage-hero__form {
	line-height: 1.4;
}

.app-homepage-hero__label {
	color: white;
	text-shadow: 0 0 4px black;
	display: inline-block;
	font-weight: bold;
	font-size: $homepage-hero--label-font-size;
	margin-bottom: spacing(8);
}

@include from-tablet {
	.app-homepage-hero__label {
		font-size: $homepage-hero--label-font-size-tablet;
	}
}

.app-homepage-hero__search-container {
	position: relative;
	margin-bottom: spacing(8);
}

input.app-homepage-hero__input {
	border: 1px solid black;
	width: 100%;
	padding: spacing(8) spacing(48) spacing(8) spacing(16);
	background-color: #fff;
	border-radius: 24px;
	min-height: 48px;

	&:focus {
		outline-color: $homepage-hero--focus-outline;
	}
}

.app-homepage-hero__button {
	background-color: transparent;
	border: 0;
	position: absolute;
	height: 100%;
	padding: 0 spacing(8);
	right: 8px;
}

.app-homepage-hero__icon {
	@include u-icon;
	font-size: $homepage-hero--icon-font-size;
	vertical-align: middle;
	fill: black; // This is set here as currentColor from u-icon renders the icon in white in ios
}

.app-homepage-hero__stats {
	color: white;
	text-shadow: 0 0 4px black;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding-bottom: spacing(32);
	margin-top: spacing(48);
}

.app-homepage-hero__stats-item {
	display: flex;
	align-items: baseline;
	line-height: 1.4;
	margin: 0;
	padding-bottom: spacing(8);
}

.app-homepage-hero__stats-number {
	font-size: $homepage-hero--stat-number-font-size;
	font-weight: bold;
	padding-right: 3px;
}

.app-homepage-hero__stats-label {
	font-size: $homepage-hero--stat-label-font-size;
}

.app-homepage-hero--search-link-container {
	color: $t-a-text-reversed;
}

.app-homepage-hero--advanced-search-link {
	@include u-link-inherit;
	margin-top: spacing(4);
	font-size: $t-a-typography-font-size-s;
}

@include from-tablet {
	.app-homepage-hero__stats {
		flex-direction: row;
	}

	.app-homepage-hero__stats-item {
		flex-direction: column;
		padding-bottom: spacing(16);

		&:not(:last-child) {
			padding-right: spacing(64);
		}
	}

	.app-homepage-hero__stats-number {
		font-size: $homepage-hero--stat-number-font-size-tablet;
		padding-right: 0;
	}

	.app-homepage-hero__stats-label {
		font-size: $homepage-hero--stat-label-font-size-tablet;
	}
}
