.app-homepage-card-layout {
	display: flex;
	flex-direction: column;

	> * {
		&:not(:last-child) {
			margin-bottom: spacing(8);
		}
	}

	+ .app-homepage-card-layout {
		padding-top: spacing(8);
	}
}

@include from-desktop {
	.app-homepage-card-layout {
		flex-direction: row;

		> * {
			flex: 1 1 261px; // matches trending research at widest

			&:not(:last-child) {
				margin-bottom: 0;
				margin-right: spacing(16);
			}
		}

		+ .app-homepage-card-layout {
			padding-top: spacing(24);
		}
	}
}
