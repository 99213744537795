$homepage-tagline-url: '/oscar-static/images/homepage/homepage-tagline.svg';
$homepage-tagline-border-color: $t-a-palette-information-orange;
$homepage-tagline-icon-color: #08c; // blue-400
$homepage-tagline-margin-top: -74px;
$homepage-tagline-margin-top-desktop: $homepage-tagline-margin-top + $homepage-tagline-margin-top ;

.app-homepage-tagline {
	margin-top: $homepage-tagline-margin-top;
	margin-bottom: spacing(32);

	> div {
		padding-top: spacing(16);
	}
}

.app-homepage-tagline .app-homepage-masthead__container {
	padding: 0 16px;
}

@include from-desktop {
	.app-homepage-tagline {
		margin-top: $homepage-tagline-margin-top-desktop;
		margin-bottom: spacing(64);

		> div {
			padding-top: spacing(64);
		}
	}
}

@include from-desktop {
	.app-homepage-tagline__main-content {
		padding-top: spacing(24);
	}
}

.app-homepage-tagline__rhombus {
	border-color: $homepage-tagline-border-color;
	border-image: url($homepage-tagline-url) 80;
	border-style: inset;
	border-width: 60px;
	height: auto;
	min-height: 233px;
	max-width: 233px;
	margin-bottom: spacing(24);
}

@include from-desktop {
	.app-homepage-tagline__rhombus {
		border-width: 80px;
		min-height: 300px;
		min-width: 300px;
		width: auto;
	}
}

.app-homepage-tagline__heading {
	margin-left: -30px;
	margin-top: 18px;
	width: 190px;
}

.app-homepage-tagline__heading span {
	display: block;
}

@include from-desktop {
	.app-homepage-tagline__heading {
		font-size: 32px;
		margin-left: -42px;
	}
}

.app-homepage-tagline__icon {
	@include u-icon;
	font-size: 32px;
	margin-top: spacing(16);
	color: $homepage-tagline-icon-color;
}
