@charset "UTF-8";
/* cyrillic-ext */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic-ext/MerriweatherItalic-Variable-Optimised-cyrillic-ext-subset.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic-ext/MerriweatherItalic-Variable-Optimised-cyrillic-ext-subset.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic/MerriweatherItalic-Variable-Optimised-cyrillic-subset.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic/MerriweatherItalic-Variable-Optimised-cyrillic-subset.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSansItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSansItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSansItalic-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSansItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSansItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSansItalic-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSansItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSansItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSansItalic-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic-ext/Merriweather-Variable-Optimised-cyrillic-ext-subset.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic-ext/Merriweather-Variable-Optimised-cyrillic-ext-subset.woff2") format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic/Merriweather-Variable-Optimised-cyrillic-subset.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/cyrillic/Merriweather-Variable-Optimised-cyrillic-subset.woff2") format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/Merriweather-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/Merriweather-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/Merriweather-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/Merriweather-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/Merriweather-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/Merriweather-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSans-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSans-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/vietnamese/MerriweatherSans-Variable-Optimised-vietnamese-subset.woff2") format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSans-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSans-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin-ext/MerriweatherSans-Variable-Optimised-latin-ext-subset.woff2") format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSans-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSans-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/oscar-static/fonts/merriweather/subsets/latin/MerriweatherSans-Variable-Optimised-latin-subset.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/**
 * Enhanced
 * Bootstrap the rest of the styles on cut the mustard
 */
/**
 * Abstracts
 * Sass tools, helper files, variables, functions, mixins and other config files
 * These files don’t output any CSS when compiled
 */
/**
 * Breakpoints
 * Shared media query values
 */
/**
 * Shared colors
 * Applicable to all brands and not to be overwritten
 *
 */
/**
 * Colors
 * Maps for color values & greyscale settings
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Typography
 * Font settings
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 *  Button settings
 *  Default
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Style
 * Cosmetic styling settings
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Return a greyscale color based on $context--greyscale-base
 *
 * @param {Integer} $val - lightness value between $context--greyscale-min and $context--greyscale-max
 * @param {Float} $opacity - opacity value between 0.1 and 1, to one decimal place
 *
 */
/**
 * Return a color from a color palette
 *
 * @param {String} $name - name of the color to insert
 * @param {Arglist} $keys - 0 to 2 paramaters defining map and opacity
 *
 */
/**
 * Returns either a dark or light foreground color when given the background color
 *
 * @param {Integer|String} $bgcolor - number for grays, string for colors
 * @param {Map} $map - the color map to use
 *
 */
/**
 * Calculates the sRGB luma of a colour
 * @private
 *
 * @param {*} $c - Color value
 *
 * https://lnikki.la/articles/sass-better-colour-based-on-brightness/
 * http://robots.thoughtbot.com/closer-look-color-lightness
 *
 */
/**
 * Map deep get
 * @author Hugo Giraudel
 * @private
 *
 * @param {Map} $map - Map
 * @param {Arglist} $keys - Key chain
 * @return {*} - Desired value
 *
 */
/**
 * Map key get
 * Check if a key exists in a map, return it
 * @private
 *
 * @param {Map} $map - Map
 * @param {String} $value - Key name
 * @return {*} - Key name, if found
 *
 */
/**
 * Default link style
 *
 */
/**
 * Link Text
 */
/**
 * Breakpoints
 * Media query helper
 * @group 30-mixins
 */
/**
 * Basic
 * Some default CSS styles
 */
body {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.8;
}

a {
  color: #025e8d;
}

button {
  cursor: pointer;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/**
 * Typography
 * Set base font size
 */
html {
  font-size: 100%;
}

body {
  font-size: 1.125rem;
}

/*
 * Headings
 */
h1 {
  font-size: 2.25rem;
  font-size: clamp(1.75rem, 4vw, 2.25rem);
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  font-size: 1.75rem;
  font-size: clamp(1.5rem, 4vw, 1.75rem);
  font-weight: 700;
  line-height: 1.2;
}

h3 {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 700;
  line-height: 1.2;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}

h5 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

h5 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

/**
 * Layout
 * Universal layout styles
 */
html {
  height: 100%;
  overflow-y: scroll;
  font-size: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-height: 100%;
  font-size: 1.125rem;
  color: #222222;
  background: #ffffff;
}

abbr[title] {
  text-decoration: none;
}

dd {
  margin: 0;
}

p {
  margin-top: 0;
  margin-bottom: 2rem;
}

p:last-of-type {
  margin-bottom: 0;
}

a {
  color: #025e8d;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
}
a.visited, a:visited {
  color: #01324b;
}
a.hover, a:hover {
  color: #01324b;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
a.active, a:active {
  color: #025e8d;
}
a.focus, a:focus {
  outline: 4px solid #0088cc;
}

table {
  font-size: 1rem;
  line-height: 1.8;
  border-collapse: collapse;
  border: none;
  width: 100%;
  margin-bottom: 32px;
}

th,
td {
  padding: 8px;
  vertical-align: top;
}

th {
  text-align: left;
  border-bottom: 3px solid #01324b;
}

td {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-bottom: 2px solid #dadada;
}

/**
 * Forms
 * Default form styles
 */
button:not([disabled]):focus,
input:not([disabled]):focus,
select:not([disabled]):focus,
textarea:not([disabled]):focus,
[contenteditable]:focus {
  outline: 4px solid #0088cc;
}

input[type=file]:focus-within {
  outline: 4px solid #0088cc;
}

.eds-c-button {
  cursor: pointer;
  display: inline-block;
  margin: 0;
  position: relative;
  text-decoration: none;
  text-align: center;
  width: 100%;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5rem 1.5rem;
  transition: all 0.2s;
  border-radius: 32px;
  font-weight: 700;
}
.eds-c-button span {
  vertical-align: middle;
}
.eds-c-button svg {
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
}
.eds-c-button svg:first-child {
  margin-right: 8px;
}
.eds-c-button svg:last-child {
  margin-left: 8px;
}

@media only screen and (min-width: 480px) {
  .eds-c-button {
    width: auto;
  }
}
.eds-c-button--primary {
  color: #ffffff;
  background-color: #025e8d;
  background-image: none;
  border: 2px solid transparent;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--primary svg {
  fill: currentColor;
}
.eds-c-button--primary:visited {
  color: #ffffff;
}
.eds-c-button--primary:hover {
  border: 2px solid #025e8d;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--primary:focus {
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 2px #025e8d;
  text-decoration: none;
}
.eds-c-button--primary:active {
  border: 2px solid #01324b;
  box-shadow: none;
  text-decoration: none;
  color: #01324b;
  background-color: #ffffff;
}
.eds-c-button--primary:hover, .eds-c-button--primary:focus {
  color: #025e8d;
  background-color: #ffffff;
  background-image: none;
}
.eds-c-button--primary:hover svg path, .eds-c-button--primary:focus svg path {
  fill: #025e8d;
}

.eds-c-button--secondary {
  color: #025e8d;
  background-color: #ffffff;
  background-image: none;
  border: 2px solid #025e8d;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--secondary svg {
  fill: currentColor;
}
.eds-c-button--secondary:visited {
  color: #025e8d;
}
.eds-c-button--secondary:hover {
  border: 2px solid #025e8d;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--secondary:focus {
  border: 2px solid #ffffff;
  box-shadow: none;
  text-decoration: none;
}
.eds-c-button--secondary:active {
  border: 2px solid #01324b;
  box-shadow: none;
  text-decoration: none;
  color: #ffffff;
  background-color: #01324b;
}
.eds-c-button--secondary:hover, .eds-c-button--secondary:focus {
  color: #ffffff;
  background-color: #025e8d;
  background-image: none;
}
.eds-c-button--secondary:hover svg path, .eds-c-button--secondary:focus svg path {
  fill: #ffffff;
}

.eds-c-button--tertiary {
  color: #025e8d;
  background-color: #f0f7fc;
  background-image: none;
  border: 2px solid #f0f7fc;
  box-shadow: none;
  text-decoration: underline;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
}
.eds-c-button--tertiary span {
  vertical-align: baseline;
}
.eds-c-button--tertiary svg {
  fill: currentColor;
}
.eds-c-button--tertiary:visited {
  color: #025e8d;
}
.eds-c-button--tertiary:hover {
  border: 2px solid #f0f7fc;
  box-shadow: none;
  text-decoration: underline;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
.eds-c-button--tertiary:focus {
  border: 2px solid #f0f7fc;
  box-shadow: none;
  text-decoration: underline;
}
.eds-c-button--tertiary:active {
  border: 2px solid #f0f7fc;
  box-shadow: none;
  text-decoration: underline;
  color: #01324b;
  background-color: #f0f7fc;
}
.eds-c-button--tertiary:hover, .eds-c-button--tertiary:focus {
  color: #025e8d;
  background-color: #f0f7fc;
  background-image: none;
}
.eds-c-button--tertiary:hover svg path, .eds-c-button--tertiary:focus svg path {
  fill: #025e8d;
}

.eds-c-button--small {
  font-size: 0.875rem;
  padding: 0.25rem 1rem;
}
.eds-c-button--small svg {
  width: 1rem;
  height: 1rem;
}

/**
 * Card
 * Default branding for component
 */
.eds-c-card-composable {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  color: #222222;
  background-color: #ffffff;
  overflow: hidden;
  font-family: inherit;
  border-radius: 8px;
  border: 1px solid #c5e0f4;
}
.eds-c-card-composable.eds-c-card-composable--faux-link {
  position: relative;
}
.eds-c-card-composable.eds-c-card-composable--faux-link:hover {
  background-color: #c5e0f4;
}
.eds-c-card-composable.eds-c-card-composable--faux-link:hover .eds-c-card-composable__sidebar--with-separator::before {
  background-color: #ffffff;
}
.eds-c-card-composable.eds-c-card-composable--faux-link:hover .eds-c-card-composable__icon-container {
  background-color: #ffffff;
}
.eds-c-card-composable.eds-c-card-composable--faux-link:hover .eds-c-card-composable__metadata-list-horizontal div:not(:last-child) {
  border-bottom: 1px solid #ffffff;
}

.eds-c-card-composable--highlight-default {
  border-top: 10px solid #dadada;
}

.eds-c-card-composable--highlight-action {
  border-top: 10px solid #0088cc;
}

.eds-c-card-composable__picture,
.eds-c-card-composable__image {
  flex: 0 0 auto;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  margin: 0;
}
.eds-c-card-composable__picture img,
.eds-c-card-composable__image img {
  width: 100%;
  height: inherit;
  object-fit: cover;
}
@supports (aspect-ratio: 1/1) {
  .eds-c-card-composable__picture img,
  .eds-c-card-composable__image img {
    padding-bottom: 0;
    aspect-ratio: var(--eds-c-card-composable--image-aspect-ratio, 16/9);
  }
}

.eds-c-card-composable__summary {
  line-height: 1.5;
}
.eds-c-card-composable__summary.eds-c-card__summary--truncate-text {
  max-width: 650px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.eds-c-card-composable__label,
p.eds-c-card-composable__label {
  margin-bottom: 0.25rem;
}

.eds-c-card-composable__container {
  display: flex;
  flex-direction: column;
}

.eds-c-card-composable__container--with-sidebar {
  flex-direction: row;
  flex-grow: 1;
}

.eds-c-card-composable__body {
  display: flex;
  flex: 1 1 auto;
  word-break: break-word;
  font-size: 0.875rem;
  padding: 16px;
}

.eds-c-card-composable__title {
  font-size: 1.125rem;
  margin-top: 0;
  margin-bottom: 8px;
  max-width: 650px;
}
.eds-c-card-composable__title > a {
  color: #222222;
}
.eds-c-card-composable__title > a:visited {
  color: #222222;
}

.eds-c-card-composable--faux-link .eds-c-card-composable__title > a {
  text-decoration: none;
}
.eds-c-card-composable--faux-link .eds-c-card-composable__title > a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.eds-c-card-composable__sidebar {
  padding: 0 16px 16px 16px;
}

.eds-c-card-composable__sidebar--with-separator {
  display: flex;
  padding: 0 16px 16px 16px;
  flex-direction: column;
  gap: 8px;
}
.eds-c-card-composable__sidebar--with-separator::before {
  content: "";
  display: block;
  height: 1px;
  flex: 0 2 auto;
  background: #c5e0f4;
}

.eds-c-card-composable__icon-container {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f0f7fc;
  padding: 16px;
  margin-right: 16px;
  color: #0088cc;
}

.eds-c-card-composable__metadata-list {
  margin: 0;
  line-height: 1.5;
}
.eds-c-card-composable__metadata-list div:not(:last-child) {
  margin-bottom: 16px;
}
.eds-c-card-composable__metadata-list dt {
  margin-bottom: 4px;
}
.eds-c-card-composable__metadata-list dd {
  font-weight: 700;
  white-space: nowrap;
}

.eds-c-card-composable__metadata-list-horizontal {
  margin: 0;
  flex-grow: 1;
}
.eds-c-card-composable__metadata-list-horizontal div {
  display: flex;
  justify-content: space-between;
}
.eds-c-card-composable__metadata-list-horizontal div:not(:last-child) {
  border-bottom: 1px solid #c5e0f4;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
.eds-c-card-composable__metadata-list-horizontal dt {
  margin-bottom: 0;
}
.eds-c-card-composable__metadata-list-horizontal dd {
  font-weight: 700;
}
.eds-c-card-composable__metadata-list-horizontal dd:not(:last-child) {
  margin-bottom: 16px;
}

.eds-c-card-composable__journal-cover img {
  border-radius: 8px;
  max-width: 100%;
}

.eds-c-card-composable__body-journal {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 1fr auto;
  gap: 16px 16px;
  grid-template-areas: "eds-c-card-composable__journal-cover eds-c-card-composable__journal-title" "eds-c-card-composable__journal-description eds-c-card-composable__journal-description";
}

.eds-c-card-composable__journal-cover {
  grid-area: eds-c-card-composable__journal-cover;
}

.eds-c-card-composable__journal-title {
  grid-area: eds-c-card-composable__journal-title;
}

.eds-c-card-composable__journal-description {
  grid-area: eds-c-card-composable__journal-description;
}

@media only screen and (min-width: 480px) {
  .eds-c-card-composable--horizontal.eds-c-card-composable--highlight-default {
    border-top: 1px solid #c5e0f4;
    border-left: 10px solid #dadada;
  }
  .eds-c-card-composable--horizontal.eds-c-card-composable--highlight-action {
    border-top: 1px solid #c5e0f4;
    border-left: 10px solid #0088cc;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__container--to-sidebar {
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__sidebar {
    width: 280px;
    padding: 16px 16px 16px 0;
    flex-grow: 1;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__sidebar--with-separator {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__sidebar--with-separator::before {
    width: 1px;
    height: auto;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__title {
    font-size: 1.5rem;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__body {
    flex-basis: 0;
    flex-grow: 999;
    min-width: 400px;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__body-journal {
    grid-template-columns: 115px 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "eds-c-card-composable__journal-cover eds-c-card-composable__journal-title" "eds-c-card-composable__journal-cover eds-c-card-composable__journal-description";
    gap: 0 16px;
    flex-basis: 0;
    flex-grow: 999;
    min-width: 400px;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__journal-cover {
    grid-area: eds-c-card-composable__journal-cover;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__journal-title {
    grid-area: eds-c-card-composable__journal-title;
  }
  .eds-c-card-composable--horizontal .eds-c-card-composable__journal-description {
    grid-area: eds-c-card-composable__journal-description;
  }
}
/**
 * Card
 * Default branding for component
 */
.eds-c-card {
  position: relative;
  text-decoration: none;
  display: flex;
  color: #222222;
}
.eds-c-card.eds-c-card--stretch-height {
  height: 100%;
}
.eds-c-card:focus {
  border-radius: 8px;
}

.eds-c-card__container {
  font-size: 0.875rem;
  min-width: 0;
  position: relative;
  overflow: hidden;
  font-family: inherit;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  flex: 1;
}
.eds-c-card__container img {
  width: 100%;
  height: inherit;
  object-fit: cover;
}
@supports (aspect-ratio: 1/1) {
  .eds-c-card__container img {
    padding-bottom: 0;
    aspect-ratio: var(--eds-c-card--image-aspect-ratio, 16/9);
  }
}
.eds-c-card__container.eds-c-card__container--image, .eds-c-card__container.eds-c-card__container--no-image {
  background-color: #f0f7fc;
  border-radius: 8px;
}
.eds-c-card__container.eds-c-card__container--image:hover, .eds-c-card__container.eds-c-card__container--no-image:hover {
  background-color: #c5e0f4;
}
.eds-c-card__container.eds-c-card__container--icon {
  border-radius: 8px;
}
.eds-c-card__container.eds-c-card__container--icon .eds-c-card__inner {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1 0 100%;
  gap: 16px;
  border: 1px solid #c5e0f4;
  border-radius: 8px;
}
.eds-c-card__container.eds-c-card__container--icon .eds-c-card__icon-container {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f0f7fc;
  padding: 16px;
  margin-right: 16px;
  color: #0088cc;
}
.eds-c-card__container.eds-c-card__container--icon:hover {
  background-color: #c5e0f4;
}
.eds-c-card__container.eds-c-card__container--icon:hover .eds-c-card__icon-container {
  background-color: #ffffff;
}
.eds-c-card__container.eds-c-card__container--metadata {
  flex-direction: column;
  border-radius: 8px;
}
.eds-c-card__container.eds-c-card__container--metadata .eds-c-card__inner {
  background-color: #f0f7fc;
}
.eds-c-card__container.eds-c-card__container--metadata .eds-c-card__inner--white-bg {
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #c5e0f4;
}
.eds-c-card__container.eds-c-card__container--metadata:hover .eds-c-card__inner {
  background-color: #c5e0f4;
}
.eds-c-card__container.eds-c-card__container--metadata:hover .eds-c-card__metadata::before {
  border-top: 1px solid #ffffff;
}
.eds-c-card__container.eds-c-card__container--highlight {
  border-top: 10px solid #0088cc;
}
.eds-c-card__container.eds-c-card__container--highlight .eds-c-card__inner--white-bg {
  border-radius: unset;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-left: 1px solid #c5e0f4;
  border-right: 1px solid #c5e0f4;
  border-bottom: 1px solid #c5e0f4;
}

.eds-c-card__body {
  display: flex;
  flex: 1 1 auto;
  word-break: break-word;
  font-size: 0.875rem;
  padding: 16px;
}

.eds-c-card__content {
  font-weight: 400;
}

.eds-c-card__label {
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.eds-c-card__title {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 700;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 8px;
  max-width: 650px;
}

.eds-c-card__title > a {
  text-decoration: none;
  color: #222222;
}
.eds-c-card__title > a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.eds-c-card__title > a:visited {
  color: #222222;
}

.eds-c-card__title--small {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}

[lang=de] .eds-c-card__title {
  -webkit-hyphens: auto;
  hyphens: auto;
}

.eds-c-card__summary {
  line-height: 1.5;
  max-width: 650px;
}
.eds-c-card__summary.eds-c-card__summary--truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.eds-c-card__image {
  flex: 0 0 auto;
  width: 100%;
  display: inline-block;
  overflow: hidden;
  margin: 0;
}

.eds-c-card__icon {
  fill: #ffffff;
  width: 32px;
  height: 32px;
}

.eds-c-card__metadata {
  padding: 0 16px 16px 16px;
}
.eds-c-card__metadata::before {
  content: "";
  display: block;
  border-top: 1px solid #c5e0f4;
}
.eds-c-card__metadata .eds-c-card__metadata-list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 16px;
}
.eds-c-card__metadata .eds-c-card__metadata-list-description {
  font-weight: 700;
}
.eds-c-card__metadata .eds-c-card__metadata-list-description:not(:last-child) {
  margin-bottom: 16px;
}

@media only screen and (min-width: 480px) {
  .eds-c-card--horizontal .eds-c-card__container {
    flex-direction: row;
    gap: 16px;
  }
  .eds-c-card--horizontal .eds-c-card__container.eds-c-card__container--icon .eds-c-card__inner--white-bg, .eds-c-card--horizontal .eds-c-card__container.eds-c-card__container--metadata .eds-c-card__inner--white-bg {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 1px solid #c5e0f4;
    border-right: 1px solid #c5e0f4;
    border-bottom: 1px solid #c5e0f4;
  }
  .eds-c-card--horizontal .eds-c-card__container.eds-c-card__container--metadata .eds-c-card__inner {
    display: flex;
    flex-basis: 100%;
  }
  .eds-c-card--horizontal .eds-c-card__container.eds-c-card__container--metadata:hover .eds-c-card__metadata::before {
    background: #ffffff;
  }
  .eds-c-card--horizontal .eds-c-card__container.eds-c-card__container--metadata:hover .eds-c-card__metadata-list {
    border-top: unset;
  }
  .eds-c-card--horizontal .eds-c-card__container.eds-c-card__container--highlight {
    border-top: none;
    border-left: 10px solid #0088cc;
  }
  .eds-c-card--horizontal .eds-c-card__container.eds-c-card__container--highlight .eds-c-card__inner--white-bg {
    border-bottom-left-radius: unset;
    border-top-left-radius: unset;
    border-left: unset;
  }
  .eds-c-card--horizontal .eds-c-card__container img {
    max-width: 258px;
  }
  .eds-c-card--horizontal .eds-c-card__image {
    display: flex;
    flex: 0 1 auto;
    width: auto;
    min-width: 200px;
    text-align: center;
  }
  .eds-c-card--horizontal .eds-c-card__metadata {
    display: flex;
    flex: 0 0 200px;
    padding: 16px 16px 16px 0;
  }
  .eds-c-card--horizontal .eds-c-card__metadata::before {
    content: "";
    display: block;
    width: 1px;
    flex: 0 2 auto;
    background: #c5e0f4;
  }
  .eds-c-card--horizontal .eds-c-card__metadata .eds-c-card__metadata-list {
    padding-top: unset;
    border-top: unset;
    padding-left: 16px;
  }
  .eds-c-card--horizontal .eds-c-card__metadata .eds-c-card__metadata-list:not(:last-child) {
    margin-bottom: 16px;
  }
}

.eds-c-footer {
  font-size: 1rem;
  background-color: #01324b;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-top: 32px;
  padding-bottom: 32px;
  color: #ffffff;
  line-height: 1.4;
}

.eds-c-footer__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}
@media print {
  .eds-c-footer__container {
    display: none;
  }
}

.eds-c-footer__links {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 32px;
}
.eds-c-footer__links li {
  display: inline-block;
}
.eds-c-footer__links li:not(:last-child) {
  margin-right: 24px;
}

.eds-c-footer__user {
  color: #ffffff;
  font-size: 1rem;
  margin-bottom: 24px;
}

.eds-c-footer__user-info {
  margin-bottom: 16px;
  margin-top: 0;
  line-height: 1.4;
}

.eds-c-footer__legal {
  color: #ffffff;
  font-size: 1rem;
  margin-top: 4px;
  margin-bottom: 0;
}

.eds-c-footer__link {
  line-height: 1.4;
  color: inherit;
}
.eds-c-footer__link.visited, .eds-c-footer__link:visited {
  color: inherit;
}
.eds-c-footer__link.hover, .eds-c-footer__link:hover {
  color: inherit;
}

.eds-c-footer__link > img {
  vertical-align: middle;
}

button.eds-c-footer__link {
  border: none;
  background: none;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: inherit;
  padding: 0;
  text-align: left;
}

.eds-c-footer__button-text {
  color: #025e8d;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: 0.0625rem;
  text-underline-offset: 0.25rem;
}
.eds-c-footer__button-text.visited, .eds-c-footer__button-text:visited {
  color: #01324b;
}
.eds-c-footer__button-text.hover, .eds-c-footer__button-text:hover {
  color: #01324b;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}
.eds-c-footer__button-text.active, .eds-c-footer__button-text:active {
  color: #025e8d;
}
.eds-c-footer__button-text.focus, .eds-c-footer__button-text:focus {
  outline: 4px solid #0088cc;
}
.eds-c-footer__button-text {
  color: inherit;
}
.eds-c-footer__button-text.visited, .eds-c-footer__button-text:visited {
  color: inherit;
}
.eds-c-footer__button-text.hover, .eds-c-footer__button-text:hover {
  color: inherit;
}

.eds-c-footer__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.eds-c-footer__grid {
  display: flex;
  flex-wrap: wrap;
}
@supports (display: grid) {
  .eds-c-footer__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 32px;
  }
}

.eds-c-footer__group {
  flex: 1 1 50%;
  max-width: 50%;
  padding-right: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1024px) {
  .eds-c-footer__group {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@supports (display: grid) {
  .eds-c-footer__group {
    padding-right: 0;
    max-width: none;
    margin-bottom: 0;
  }
}

.eds-c-footer__group--separator {
  border-bottom: 2px solid #ffffff;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.eds-c-footer__heading {
  color: #ffffff;
  margin-bottom: 16px;
  font-weight: bold;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.eds-c-footer__heading--visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.eds-c-footer__item:not(:last-child) {
  margin-bottom: 16px;
}

.eds-c-header {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.8;
  background-color: #ffffff;
  padding: 8px 0 0;
  border-bottom: 2px solid #01324b;
  font-size: 1rem;
  line-height: 1.5;
}

.eds-c-header__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  margin: 0 auto 8px;
  padding: 0 8px;
  justify-content: space-between;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px 16px;
}

.eds-c-header__nav {
  border-top: 2px solid #c5e0f4;
  padding-top: 4px;
  position: relative;
}

.eds-c-header__nav-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 0 8px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.eds-c-header__nav-container > *:not(:last-child) {
  margin-right: 32px;
}

.eds-c-header__link-container {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  gap: 8px 16px;
  justify-content: space-between;
}

.eds-c-header__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.eds-c-header__list-item {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  font-weight: bold;
  padding: 8px;
}
.eds-c-header__list-item:not(:last-child) {
  border-bottom: 2px solid #c5e0f4;
}

.eds-c-header__item {
  color: inherit;
}

@media only screen and (min-width: 768px) {
  .eds-c-header__item--menu {
    display: none;
    visibility: hidden;
  }
  .eds-c-header__item--menu:first-child + * {
    margin-block-start: 0;
  }
}

.eds-c-header__item--inline-links {
  display: none;
  visibility: hidden;
}
.eds-c-header__item--inline-links:first-child + * {
  margin-block-start: 0;
}
@media only screen and (min-width: 768px) {
  .eds-c-header__item--inline-links {
    display: flex;
    gap: 16px;
    visibility: visible;
  }
}

.eds-c-header__item--divider:before {
  content: "";
  position: absolute;
  border-left: 2px solid #c5e0f4;
  height: calc(100% - 16px);
  top: 8px;
  margin-left: -15px;
}

.eds-c-header__brand {
  padding: 16px 8px;
}
.eds-c-header__brand a {
  display: block;
  line-height: 1;
  text-decoration: none;
}
.eds-c-header__brand img {
  height: 1.5rem;
  width: auto;
}

.eds-c-header__link {
  display: inline-block;
  padding: 16px 8px;
  color: inherit;
  font-weight: bold;
  text-decoration-color: transparent;
  position: relative;
  word-break: normal;
  white-space: nowrap;
}
.eds-c-header__link:visited {
  color: inherit;
}
.eds-c-header__link:hover {
  color: inherit;
  text-decoration-color: inherit;
}
.eds-c-header__link:not(.eds-c-header__expander .eds-c-header__link):hover {
  text-decoration-color: transparent;
}
.eds-c-header__link:not(.eds-c-header__expander .eds-c-header__link):hover::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.eds-c-header__link--static {
  flex: 0 0 auto;
}

.eds-c-header__link.is-open {
  color: #025e8d;
}
.eds-c-header__link.is-open::after {
  content: "";
  background-color: #025e8d;
  width: 100%;
  height: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: -4px;
  left: 0;
}

.eds-c-header__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
  vertical-align: bottom;
  font-size: 1.5rem;
}
.eds-c-header__icon + * {
  margin-left: 8px;
}

.eds-c-header__expander {
  background-color: #f0f7fc;
}

.eds-c-header__search {
  display: block;
  padding: 24px 0;
}
@media only screen and (min-width: 768px) {
  .eds-c-header__search {
    max-width: 70%;
  }
}

.eds-c-header__search-container {
  position: relative;
}

.eds-c-header__search-label {
  display: inline-block;
  color: inherit;
  font-weight: bold;
  margin-bottom: 8px;
}

.eds-c-header__search-input {
  border: 1px solid black;
  width: 100%;
  padding: 8px 48px 8px 8px;
  background-color: white;
}

.eds-c-header__search-button {
  background-color: transparent;
  border: none;
  color: inherit;
  position: absolute;
  height: 100%;
  padding: 0 8px;
  right: 0;
}

.has-tethered.eds-c-header__expander {
  margin-top: -2px;
  width: 100%;
  top: 100%;
  z-index: 10;
  left: 0;
  border-bottom: 2px solid #01324b;
}

@media only screen and (min-width: 768px) {
  .has-tethered.eds-c-header__expander--menu {
    display: none;
    visibility: hidden;
  }
  .has-tethered.eds-c-header__expander--menu:first-child + * {
    margin-block-start: 0;
  }
}

.has-tethered.eds-c-header__expander--account {
  transform: translateY(8px);
  width: auto;
  left: auto;
  position: absolute;
  right: 0;
  bottom: auto;
  margin-top: 0;
}

.has-tethered .eds-c-header__heading {
  display: none;
  visibility: hidden;
}
.has-tethered .eds-c-header__heading:first-child + * {
  margin-block-start: 0;
}
.has-tethered .eds-c-header__search {
  margin: auto;
}

.eds-c-header__heading {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding: 16px 16px 0;
}

/**
 * @springernature/elements/layout-grid
 * Default skin settings
 *
 */
/* ↓ Necessary since non-Dart Sass chokes
on the min() function */
.l-grid {
  list-style: none;
  padding: 0;
}

.l-grid > * + * {
  margin-top: 1em;
  margin-top: var(--grid--gap, 1em);
}

@supports (display: grid) {
  .l-grid > * {
    margin: 0;
  }
  .l-grid {
    display: grid;
    gap: 1em;
    gap: var(--grid--gap, 1em);
    grid-template-columns: repeat(auto-fit, minmax(var(--grid--basis, min(300px, 100%)), 1fr));
  }
}
/**
 * @springernature/elements/layout-stack
 * Default skin settings
 *
 */
.l-stack > * {
  margin: 0;
}

.l-stack > * + * {
  margin-top: 1.5em; /* ← core browser fallback */
  margin-top: var(--stack--gap, 1.5em);
}

@supports (gap: 1.5em) {
  .l-stack > * {
    margin: 0;
  }
  .l-stack {
    display: flex;
    flex-direction: column;
    gap: var(--stack--gap, 1.5em);
  }
}
/**
 * Meta
 * Default branding for component
 */
.c-meta {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.875rem;
  color: #000000;
  line-height: 1.5;
}

.c-meta--large {
  font-size: 1.25rem;
}
.c-meta--large .c-meta__item {
  margin-bottom: 20px;
}

.c-meta__item:not(:last-child) {
  border-right: 2px solid #999999;
  padding-right: 8px;
  margin-right: 8px;
  line-height: 1;
  border-color: #c5e0f4;
}

.c-meta__item--spacing:not(:last-child) {
  margin-bottom: 4px;
}

@media only screen and (max-width: 767px) {
  .c-meta__item--block-sm-max {
    display: block;
  }
  .c-meta__item--block-sm-max:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .c-meta__item--block-at-lg {
    display: block;
  }
  .c-meta__item--block-at-lg:not(:last-child) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

.c-meta__type {
  text-transform: none;
  font-weight: 700;
}

.c-meta__link {
  color: #222222;
  text-decoration: underline;
}

.c-meta__link:visited {
  color: #222222;
}

/**
 * @springernature/eds/skip-link
 * Default skin settings
 *
 */
/**
 * @springernature/eds/skip-link
 * A full width link which is hidden for mouse users
 *
 */
.c-skip-link {
  background: #01324b;
  color: white;
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  padding: 8px;
  position: absolute;
  width: 100%;
  inset: 0;
  bottom: auto;
  z-index: 9999;
  transform: translateY(-100%);
}
@media (prefers-reduced-motion: reduce) {
  .c-skip-link {
    transition: top 0.3s ease-in-out;
  }
}
@media print {
  .c-skip-link {
    display: none;
  }
}
.c-skip-link:link, .c-skip-link:active, .c-skip-link:visited, .c-skip-link:hover {
  color: white;
}
.c-skip-link:focus {
  transform: translateY(0%);
}

.app-card-open {
  position: relative;
  border-bottom: 1px solid transparent;
  background-color: #ffffff;
  padding: 24px 0;
  border-top: 1px solid #c5e0f4;
}
.app-card-open a:not(.app-card-open__link) {
  position: relative;
}

.app-card-open--last {
  border-bottom: 1px solid #c5e0f4;
}

.app-card-open__main {
  flex: 1 1 auto;
  word-break: break-word;
  align-self: baseline;
}

.app-card-open__side {
  position: absolute;
  right: 0;
  top: 24px;
  overflow: hidden;
}

.app-card-open--portrait-ratio .app-card-open__side {
  overflow: auto;
}

.app-card-open img {
  border: 1px solid #cfd8dc;
  border-radius: 12px;
  width: 112px;
  aspect-ratio: 16 / 9;
}

.app-card-open.app-card-open--portrait-ratio img {
  border-radius: 0;
  width: 45px;
  aspect-ratio: auto;
}

.app-card-open__heading {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1rem;
}

.app-card-open__subheading::before {
  content: "";
  display: block;
  padding-top: 8px;
}

.app-card-open__link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.app-card-open__heading a {
  line-height: 1.5;
}

.app-card-open__description {
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 16px;
}
@media only screen and (min-width: 480px) {
  .app-card-open__description--larger {
    font-size: 1rem;
  }
}

.app-card-open--has-image .app-card-open__heading {
  max-width: calc(100vw - 112px - 16px - 16px - 16px);
  min-height: 58px;
  padding-right: 8px;
}

.app-card-open--has-image.app-card-open--portrait-ratio .app-card-open__heading,
.app-card-open--has-image.app-card-open--portrait-ratio .app-card-open__description {
  max-width: calc(100vw - 45px - 16px - 16px - 16px);
}
.app-card-open--has-image.app-card-open--portrait-ratio .app-card-open__heading {
  min-height: auto;
}
.app-card-open--has-image.app-card-open--portrait-ratio .app-card-open__description {
  padding-right: 8px;
}

.app-card-open__collections-list {
  margin-top: 8px;
}

.app-card-open__heading a {
  color: #000000;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.app-card-open__meta {
  margin-top: 4px;
}

.app-card-open__authors,
.app-card-open__journal {
  display: none;
  font-size: 0.875rem;
  line-height: 1.5;
}

.app-card-open__authors a,
.app-card-open__journal a {
  color: #222222;
}

.app-card-open--compact.app-card-open {
  padding: 16px 0;
}

.app-card-open--full-width .app-card-open__main,
.app-card-open--compact .app-card-open__main {
  margin-right: 0;
}

.app-card-open--compact .app-card-open__heading {
  margin-bottom: 8px;
  font-size: 1rem;
}

.app-card-open__text-container {
  font-size: 0;
}

.app-card-open__metadata-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  flex-direction: column;
}
@media only screen and (min-width: 480px) {
  .app-card-open__metadata-list {
    flex-direction: row;
  }
}

.app-card-open__metadata-list div {
  display: flex;
  border-top: 1px solid #c5e0f4;
  min-width: 124px;
  padding: 8px 0;
  justify-content: space-between;
}
@media only screen and (min-width: 480px) {
  .app-card-open__metadata-list div {
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 16px;
    border-top: 0;
  }
  .app-card-open__metadata-list div:first-child {
    padding-left: 0;
  }
}

@media only screen and (min-width: 480px) {
  .app-card-open__metadata-list div + div {
    border-left: 1px solid #c5e0f4;
  }
}

.app-card-open__metadata-list-term {
  font-size: 0.875rem;
}

.app-card-open__metadata-list-description {
  font-weight: 700;
  font-size: 0.875rem;
}
@media only screen and (min-width: 480px) {
  .app-card-open__metadata-list-description {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 480px) {
  .app-card-open {
    display: flex;
    justify-content: space-between;
  }
  .app-card-open__main {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-right: 192px;
  }
  .app-card-open--has-image .app-card-open__main {
    margin-right: 16px;
  }
  .app-card-open__side {
    position: initial;
    flex: 0 0 auto;
    overflow: hidden;
  }
  .app-card-open img {
    width: 192px;
  }
  .app-card-open--compact img {
    width: 112px;
  }
  .app-card-open__heading {
    font-size: 1.125rem;
  }
  .app-card-open--has-image .app-card-open__heading {
    max-width: initial;
    min-height: initial;
  }
  .app-card-open.app-card-open--portrait-ratio img {
    width: auto;
  }
  .app-card-open__heading,
  .app-card-open__description {
    padding-right: 0;
  }
  .app-card-open__authors,
  .app-card-open__journal {
    display: inline;
  }
}
.app-card-highlight {
  position: relative;
  display: flex;
  padding: 16px;
  background-color: #f0f7fc;
  border-top: 10px solid #3c9cd7;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: column-reverse;
  justify-content: space-between;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.app-card-highlight__main {
  display: flex;
  flex: 1 1 auto;
  word-break: break-word;
  font-size: 0.875rem;
}

.app-card-highlight__content {
  font-weight: 400;
}

.app-card-highlight__text {
  line-height: 1.7;
}

.app-card-highlight__text p {
  margin: 0;
}

.app-card-highlight__text ul {
  margin: 0;
}

.app-card-highlight__text + .app-card-highlight__cta {
  display: inline-block;
  margin-top: 8px;
}

.app-card-highlight__cta {
  font-weight: 700;
  color: #025e8d;
}
.app-card-highlight__cta:hover, .app-card-highlight__cta:focus {
  color: #025e8d;
}

.app-card-highlight__cta-icon {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.app-card-highlight__heading {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 1.125rem;
}

.app-card-highlight__heading a {
  color: #000000;
  line-height: 1.5;
}

.app-card-highlight .app-card-highlight__side {
  flex: 1 0 100%;
  margin: -16px -16px 16px -16px;
}

.app-card-highlight .app-card-highlight__side img {
  width: 100%;
  height: auto;
}

.app-card-highlight .app-card-highlight__text img {
  width: auto;
  max-width: 100%;
}

.app-card-highlight .app-card-highlight__text img.float--right {
  float: right;
}

.app-card-highlight .app-card-highlight__text img.float--right {
  float: left;
}

.app-card-highlight__icon {
  fill: #025e8d;
  width: 72px;
  height: 72px;
}

@media only screen and (min-width: 480px) {
  .app-card-highlight {
    flex-direction: row;
    border-left: 10px solid #3c9cd7;
    border-top: none;
  }
  .app-card-highlight__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .app-card-highlight .app-card-highlight__side {
    flex: 0 0 auto;
    align-self: center;
    margin: -16px -16px -16px 16px;
  }
  .app-card-highlight__heading {
    margin-bottom: 0;
  }
  .app-card-highlight .app-card-highlight__side img {
    border-radius: 0 12px 12px 0;
    width: 256px;
    height: 144px;
    aspect-ratio: 16 / 9;
  }
  .app-card-highlight .app-card-highlight__text img {
    max-width: 40%;
  }
  .app-card-highlight__side {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .app-card-highlight--stack {
    flex-direction: column-reverse;
    border-left: none;
    border-top: 10px solid #3c9cd7;
  }
  .app-card-highlight--stack .app-card-highlight__side img {
    border-radius: initial;
  }
  .app-card-highlight__content {
    display: block;
    flex-direction: unset;
    justify-content: unset;
  }
  .app-card-highlight__heading {
    margin-bottom: 8px;
  }
  .app-card-highlight--stack .app-card-highlight__side {
    max-width: none;
    margin: -16px -16px 0 -16px;
  }
  .app-card-highlight--stack .app-card-highlight__heading {
    margin-top: 16px;
  }
}
.app-card-collection {
  position: relative;
  display: flex;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #fff;
  padding: 16px;
  border: 1px solid #c5e0f4;
  border-top: 10px solid #3c9cd7;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
}

.app-card-collection__main {
  display: flex;
  flex: 1 1 auto;
  word-break: break-word;
  font-size: 0.875rem;
  padding-bottom: 16px;
}

.app-card-collection__main + .app-card-collection__side {
  border-top: 1px solid #c5e0f4;
}

.app-card-collection__side {
  padding-top: 16px;
}

.app-card-collection__content {
  font-weight: 400;
}

.app-card-collection__text {
  line-height: 1.7;
}

.app-card-collection__text p {
  margin: 0;
}

.app-card-collection__heading {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 1.25rem;
}

.app-card-collection__heading a {
  color: #000000;
  line-height: 1.5;
}
.app-card-collection__heading a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.app-card-collection__description-list {
  margin: 0;
  font-size: 0.875rem;
  margin-bottom: 16px;
}

.app-card-collection__description-list:last-of-type {
  margin-bottom: 0;
}

.app-card-collection__description-list dd {
  font-weight: 700;
  margin: 0;
}

.app-card-collection__status-message {
  margin-top: 16px;
}

.app-card-collection__status-message svg {
  fill: #025e8d;
  margin-right: 8px;
}

@media only screen and (min-width: 768px) {
  .app-card-collection {
    flex-direction: row;
    border-left: 10px solid #3c9cd7;
    border-top: 1px solid #c5e0f4;
  }
  .app-card-collection--stack {
    flex-direction: column;
    border-left: 1px solid #c5e0f4;
    border-top: 10px solid #3c9cd7;
  }
  .app-card-collection__main {
    padding-right: 16px;
    padding-bottom: 0;
  }
  .app-card-collection__main + .app-card-collection__side {
    border-left: 1px solid #c5e0f4;
    border-top: none;
  }
  .app-card-collection__side {
    min-width: 240px;
    margin-bottom: 0;
    padding-left: 16px;
    padding-top: 0;
  }
}
@media only screen and (min-width: 768px) {
  .app-homepage-about {
    columns: 2;
    column-gap: 64px;
  }
}
.app-homepage-about > p {
  font-size: 1rem;
  margin-top: 0;
}
@media only screen and (min-width: 768px) {
  .app-homepage-about > p {
    margin-bottom: 0;
  }
}

.app-homepage-brands {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  margin-bottom: 32px;
}

@media only screen and (min-width: 1024px) {
  .app-homepage-brands {
    margin-bottom: 64px;
  }
}
.app-homepage-brands__heading {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

.app-homepage-brands__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  border-top: 1px solid #cedbe0;
  border-bottom: 1px solid #cedbe0;
  padding-top: 24px;
  padding-bottom: 24px;
}
.app-homepage-brands__list li {
  flex: 1 1 50%;
  margin: 0 0 48px;
}
.app-homepage-brands__list li:not(:last-child) {
  padding-right: 24px;
}
.app-homepage-brands__list li:nth-last-child(1), .app-homepage-brands__list li:nth-last-child(2) {
  margin: 0;
}

@media only screen and (min-width: 480px) {
  .app-homepage-brands__list li {
    flex: 1 1 33%;
  }
  .app-homepage-brands__list li:nth-last-child(3) {
    margin: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .app-homepage-brands__list {
    flex-wrap: wrap;
    text-align: left;
  }
  .app-homepage-brands__list li {
    flex: none;
    margin: 0;
  }
}
.app-homepage-card {
  display: flex;
  position: relative;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #c5e0f4;
  border-radius: 8px;
  overflow: hidden;
  justify-content: space-between;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.app-homepage-card--stack {
  flex-direction: column;
}

.app-homepage-card--mutant {
  flex: 1 1 auto;
  flex-direction: row-reverse;
  border: none;
  border-top: 1px solid #c5e0f4;
  padding: 24px 0 8px;
  margin: 0;
  border-radius: 0;
}
@media only screen and (min-width: 1220px) {
  .app-homepage-card--mutant {
    flex-direction: column;
    padding: 16px;
    border: 1px solid #c5e0f4;
    border-radius: 8px;
    flex: 1 1 262px;
  }
}
.app-homepage-card--mutant .app-homepage-card__main {
  margin-right: 16px;
}
@media only screen and (min-width: 1220px) {
  .app-homepage-card--mutant .app-homepage-card__main {
    margin-right: 0;
  }
}
.app-homepage-card--mutant .app-homepage-card__side {
  flex: 0 0 auto;
  max-width: 114px;
  width: 100%;
}
.app-homepage-card--mutant .app-homepage-card__side img {
  border: 1px solid #cfd8dc;
  border-radius: 12px;
}
@media only screen and (min-width: 1220px) {
  .app-homepage-card--mutant .app-homepage-card__side {
    border: none;
    max-width: none;
    width: auto;
  }
}
@media (min-width: 640px) {
  .app-homepage-card--mutant .app-homepage-card__side {
    max-width: 192px;
  }
}
@media only screen and (min-width: 1220px) {
  .app-homepage-card--mutant .app-homepage-card__side {
    max-width: none;
  }
}
.app-homepage-card--mutant .app-homepage-card__side--flush {
  margin: 0;
}
@media only screen and (min-width: 1220px) {
  .app-homepage-card--mutant .app-homepage-card__side--flush {
    margin: -16px -16px 16px -16px;
  }
}

.app-homepage-card__main {
  display: flex;
  flex: 1 1 auto;
  word-break: break-word;
  font-size: 0.875rem;
}

.app-homepage-card__side {
  flex: 0 1 auto;
}

.app-homepage-card__side--flush {
  margin: -16px -16px 16px -16px;
}

.app-homepage-card__content {
  display: flex;
  flex-direction: column;
  font-weight: 400;
}

.app-homepage-card__content--row {
  flex-direction: row;
}
.app-homepage-card__content--row > *:first-child {
  align-self: center;
  padding-right: 16px;
  text-decoration: none;
  line-height: 1.5;
}
.app-homepage-card__content--row > *:first-child:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}

.app-homepage-card__content--padding {
  padding: 16px 0;
}

.app-homepage-card__title-link {
  line-height: 1.4;
  color: currentColor;
}
.app-homepage-card__title-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.app-homepage-card__title-link:hover, .app-homepage-card__title-link:visited {
  color: currentColor;
}

.app-homepage-card__text {
  line-height: 1.7;
  position: relative;
}
.app-homepage-card__text > div:first-child {
  margin-bottom: 4px;
}
.app-homepage-card__text p {
  margin: 0;
}
.app-homepage-card__text ul {
  margin: 0;
}

.app-homepage-card__heading {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1.125rem;
}
.app-homepage-card__heading a {
  color: #222222;
  line-height: 1.5;
}

.app-homepage-card img {
  width: 100%;
}

.app-homepage-card__cover-image {
  flex: 0 0 auto;
}
.app-homepage-card__cover-image img {
  border: 1px solid white;
  max-width: 73px;
}

.app-homepage-card__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  background-color: #eff6fb;
  border-radius: 50%;
}
.app-homepage-card__icon-container img {
  width: 60%;
}
.app-homepage-card__icon-container + * {
  padding-left: 16px;
}

@media only screen and (min-width: 480px) {
  .app-homepage-card__icon-container {
    width: 62px;
    height: 62px;
  }
  .app-homepage-card__icon-container img {
    width: auto;
  }
}
.app-homepage-card__description-list {
  padding-top: 16px;
  margin-block-start: 0;
  margin-block-end: 0;
}
.app-homepage-card__description-list > div {
  display: flex;
  justify-content: space-between;
}
.app-homepage-card__description-list > div:not(:last-child) {
  border-bottom: 1px solid #c5e0f4;
  margin-bottom: 8px;
  padding-bottom: 8px;
}
.app-homepage-card__description-list dd {
  font-weight: 700;
}

.app-homepage-card__link {
  color: inherit;
  font-weight: bold;
}
.app-homepage-card__link.visited, .app-homepage-card__link:visited {
  color: inherit;
}
.app-homepage-card__link.hover, .app-homepage-card__link:hover {
  color: inherit;
}

.app-homepage-card__link--faux::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@media only screen and (min-width: 480px) {
  .app-homepage-card__content {
    flex-direction: column;
    justify-content: space-between;
  }
  .app-homepage-card__content--row {
    flex-direction: row;
    justify-content: flex-start;
  }
  .app-homepage-card__content--row > *:first-child {
    align-self: flex-start;
  }
  .app-homepage-card__text--flex {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .app-homepage-card__description {
    flex-grow: 1;
  }
}
.app-card-highlight {
  flex-direction: row;
  border-left: 10px solid #3c9cd7;
  border-top: none;
  padding: 24px 8px;
  position: relative;
}

.app-card-highlight__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app-card-highlight .app-card-highlight__side {
  flex: 0 0 auto;
  align-self: center;
  margin: -24px -8px -24px 8px;
  max-width: 55%;
}

@media only screen and (min-width: 480px) {
  .app-card-highlight .app-card-highlight__side {
    max-width: 30%;
  }
}
.app-card-highlight__heading {
  margin-bottom: 0;
  font-size: 1rem;
}
.app-card-highlight__heading a {
  text-decoration: none;
  line-height: 1.2;
}
.app-card-highlight__heading a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.app-card-highlight__heading a:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.25rem;
  text-underline-offset: 0.25rem;
}

.app-card-highlight__heading--secondary {
  font-size: 0.875rem;
}

.app-card-highlight img {
  border-radius: 0 12px 12px 0;
  max-width: 258px;
}

.app-card-highlight__side {
  margin-bottom: 0;
}

.app-card-highlight--normal-order-stack .app-card-highlight__side {
  margin: -24px 0 0 -24px;
}

@media only screen and (min-width: 1024px) {
  .app-card-highlight {
    padding: 24px;
  }
  .app-card-highlight--normal-order-stack {
    flex-direction: column;
    border-left: none;
    border-top: 10px solid #3c9cd7;
  }
  .app-card-highlight--normal-order-stack .app-card-highlight__side {
    align-self: start;
    margin: 0;
  }
  .app-card-highlight--stack {
    flex-direction: column-reverse;
    border-left: none;
    border-top: 10px solid #3c9cd7;
  }
  .app-card-highlight__content {
    display: block;
    flex-direction: unset;
    justify-content: unset;
  }
  .app-card-highlight--stack .app-card-highlight__side {
    max-width: none;
    margin: -24px -24px 0 -24px;
  }
  .app-card-highlight--stack .app-card-highlight__heading {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 1.5rem;
  }
  .app-card-highlight__heading--secondary {
    font-size: 1.25rem;
  }
  .app-card-highlight--stack img {
    max-width: initial;
    border-radius: initial;
  }
}
.app-homepage-card-layout {
  display: flex;
  flex-direction: column;
}
.app-homepage-card-layout > *:not(:last-child) {
  margin-bottom: 8px;
}
.app-homepage-card-layout + .app-homepage-card-layout {
  padding-top: 8px;
}

@media only screen and (min-width: 1024px) {
  .app-homepage-card-layout {
    flex-direction: row;
  }
  .app-homepage-card-layout > * {
    flex: 1 1 261px;
  }
  .app-homepage-card-layout > *:not(:last-child) {
    margin-bottom: 0;
    margin-right: 16px;
  }
  .app-homepage-card-layout + .app-homepage-card-layout {
    padding-top: 24px;
  }
}
.app-homepage-featured-books__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, minmax(142px, 50%));
  justify-content: space-evenly;
}
.app-homepage-featured-books__list > li {
  border: 1px solid #c5e0f4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  padding-bottom: 16px;
}
.app-homepage-featured-books__list img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.app-homepage-featured-books__cover {
  min-height: 265px;
  background-color: #f0f7fc;
}

.app-homepage-featured-books__content {
  line-height: 1.2;
  padding: 8px;
}

.app-homepage-featured-books__link {
  font-size: 0.875rem;
  font-weight: 700;
  color: inherit;
}
.app-homepage-featured-books__link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.app-homepage-featured-books__link:hover, .app-homepage-featured-books__link:visited {
  color: inherit;
}

@media only screen and (min-width: 480px) {
  .app-homepage-featured-books__list {
    grid-template-columns: repeat(auto-fit, 142px);
  }
  .app-homepage-featured-books__cover {
    min-height: 220px;
  }
}
.app-homepage-hero {
  position: relative;
  min-height: 332px;
}

.app-homepage-hero__image {
  background-color: #011d44;
  height: 100%;
  position: absolute;
  z-index: -1;
  width: 100%;
  object-fit: cover;
  object-position: left;
}

.app-homepage-hero__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding-top: 48px;
  padding-bottom: 48px;
}

.app-homepage-hero__form {
  line-height: 1.4;
}

.app-homepage-hero__label {
  color: white;
  text-shadow: 0 0 4px black;
  display: inline-block;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 8px;
}

@media only screen and (min-width: 480px) {
  .app-homepage-hero__label {
    font-size: 1.25rem;
  }
}
.app-homepage-hero__search-container {
  position: relative;
  margin-bottom: 8px;
}

input.app-homepage-hero__input {
  border: 1px solid black;
  width: 100%;
  padding: 8px 48px 8px 16px;
  background-color: #fff;
  border-radius: 24px;
  min-height: 48px;
}
input.app-homepage-hero__input:focus {
  outline-color: #f0f7fc;
}

.app-homepage-hero__button {
  background-color: transparent;
  border: 0;
  position: absolute;
  height: 100%;
  padding: 0 8px;
  right: 8px;
}

.app-homepage-hero__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
  font-size: 24px;
  vertical-align: middle;
  fill: black;
}

.app-homepage-hero__stats {
  color: white;
  text-shadow: 0 0 4px black;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 32px;
  margin-top: 48px;
}

.app-homepage-hero__stats-item {
  display: flex;
  align-items: baseline;
  line-height: 1.4;
  margin: 0;
  padding-bottom: 8px;
}

.app-homepage-hero__stats-number {
  font-size: 1rem;
  font-weight: bold;
  padding-right: 3px;
}

.app-homepage-hero__stats-label {
  font-size: 1rem;
}

.app-homepage-hero--search-link-container {
  color: #ffffff;
}

.app-homepage-hero--advanced-search-link {
  color: inherit;
  margin-top: 4px;
  font-size: 1rem;
}
.app-homepage-hero--advanced-search-link.visited, .app-homepage-hero--advanced-search-link:visited {
  color: inherit;
}
.app-homepage-hero--advanced-search-link.hover, .app-homepage-hero--advanced-search-link:hover {
  color: inherit;
}

@media only screen and (min-width: 480px) {
  .app-homepage-hero__stats {
    flex-direction: row;
  }
  .app-homepage-hero__stats-item {
    flex-direction: column;
    padding-bottom: 16px;
  }
  .app-homepage-hero__stats-item:not(:last-child) {
    padding-right: 64px;
  }
  .app-homepage-hero__stats-number {
    font-size: 1.75rem;
    padding-right: 0;
  }
  .app-homepage-hero__stats-label {
    font-size: 1rem;
  }
}
.app-homepage-tagline {
  margin-top: -74px;
  margin-bottom: 32px;
}
.app-homepage-tagline > div {
  padding-top: 16px;
}

.app-homepage-tagline .app-homepage-masthead__container {
  padding: 0 16px;
}

@media only screen and (min-width: 1024px) {
  .app-homepage-tagline {
    margin-top: -148px;
    margin-bottom: 64px;
  }
  .app-homepage-tagline > div {
    padding-top: 64px;
  }
}
@media only screen and (min-width: 1024px) {
  .app-homepage-tagline__main-content {
    padding-top: 24px;
  }
}
.app-homepage-tagline__rhombus {
  border-color: #f58220;
  border-image: url("/oscar-static/images/homepage/homepage-tagline.svg") 80;
  border-style: inset;
  border-width: 60px;
  height: auto;
  min-height: 233px;
  max-width: 233px;
  margin-bottom: 24px;
}

@media only screen and (min-width: 1024px) {
  .app-homepage-tagline__rhombus {
    border-width: 80px;
    min-height: 300px;
    min-width: 300px;
    width: auto;
  }
}
.app-homepage-tagline__heading {
  margin-left: -30px;
  margin-top: 18px;
  width: 190px;
}

.app-homepage-tagline__heading span {
  display: block;
}

@media only screen and (min-width: 1024px) {
  .app-homepage-tagline__heading {
    font-size: 32px;
    margin-left: -42px;
  }
}
.app-homepage-tagline__icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
  font-size: 32px;
  margin-top: 16px;
  color: #08c;
}

.app-homepage-trending {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
}
@media only screen and (min-width: 768px) {
  .app-homepage-trending {
    flex-direction: row;
  }
}
.app-homepage-trending > * {
  flex: 1 1 auto;
  margin-right: 16px;
  margin-bottom: 16px;
}
@media only screen and (min-width: 768px) {
  .app-homepage-trending > * {
    flex: 1 1 315px;
  }
}
@media only screen and (min-width: 876px) {
  .app-homepage-trending > * {
    flex: 1 1 320px;
  }
}
@media only screen and (min-width: 1024px) {
  .app-homepage-trending > * {
    flex: 1 1 267px;
  }
}
@media only screen and (min-width: 1220px) {
  .app-homepage-trending > * {
    flex: 1 1 261px;
  }
}

.app-layout-section {
  min-height: 332px;
  position: relative;
}

.app-layout-section--no-background {
  min-height: 0;
}

.app-layout-section__background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 332px;
  object-fit: cover;
  object-position: left;
}

.app-layout-section__background--1 {
  background-color: #ab2500;
}

.app-layout-section__background--2 {
  background-color: #014425;
}

.app-layout-section__background--3 {
  background-color: #1e1439;
}

.app-layout-section__background--4 {
  background-color: #f0f7fc;
  min-height: auto;
  margin-bottom: 0;
}

.app-layout-section__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  padding-top: 24px;
  padding-bottom: 48px;
}

.app-layout-section__container.app-layout-section__container--no-padding-start-end {
  padding-top: 0;
  padding-bottom: 0;
}

@media only screen and (min-width: 1024px) {
  .app-layout-section__container {
    padding-top: 48px;
  }
}
.app-layout-section__heading {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 4px;
}

@media only screen and (min-width: 1024px) {
  .app-layout-section__line-break {
    display: block;
  }
}
.app-layout-section__heading--light {
  color: white;
  text-shadow: 0 0 4px black;
}

.app-layout-section__link {
  color: inherit;
}
.app-layout-section__link.visited, .app-layout-section__link:visited {
  color: inherit;
}
.app-layout-section__link.hover, .app-layout-section__link:hover {
  color: inherit;
}

.app-layout-section__text {
  font-size: 1rem;
  line-height: 1.5;
}

.app-layout-section__heading--dark {
  color: #000;
}

.app-layout-section__text--light {
  font-size: 1rem;
  color: white;
  text-shadow: 0 0 4px black;
}

.app-layout-section__heading-layout {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.app-layout-section__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  width: 48px;
  height: 48px;
  background-color: #f0f7fc;
  border-radius: 50%;
  color: #222;
  font-size: 24px;
}
.app-layout-section__icon-container + * {
  padding-left: 8px;
  flex: 0 999 auto;
}

@media only screen and (min-width: 480px) {
  .app-layout-section__icon-container {
    font-size: 32px;
    width: 64px;
    height: 64px;
  }
  .app-layout-section__icon-container img {
    width: auto;
  }
  .app-layout-section__icon-container + * {
    padding-left: 16px;
  }
}
.app-layout-section__icon-container--light {
  background-color: #fff;
}

.app-layout-section__container--padding-top {
  padding-top: 32px;
}

.app-layout-two-column {
  display: flex;
  align-content: flex-start;
  word-wrap: break-word;
  overflow-wrap: anywhere;
  flex-direction: column;
}

.app-layout-two-column > :not(.app-layout-two-column__sidebar) {
  flex-basis: 0;
  flex-grow: 999;
}

.app-layout-two-column__sidebar {
  flex-basis: 100%;
  width: 100%;
}
.app-layout-two-column__sidebar:empty {
  margin-bottom: 0;
}

@media only screen and (min-width: 1024px) {
  .app-layout-two-column {
    flex-direction: row;
    justify-content: space-between;
  }
  .app-layout-two-column__main-content {
    min-width: 0;
  }
  .app-layout-two-column__sidebar {
    flex-grow: 1;
    flex-basis: 300px;
  }
  .app-layout-two-column .app-layout-two-column__sidebar {
    flex-grow: initial;
  }
  .app-layout-two-column > :first-child {
    margin-right: 30px;
  }
}
.app-masthead__colour-1 {
  --background-color: rgb(255, 21, 0);
}

.app-masthead__colour-2 {
  --background-color: rgb(255, 64, 0);
}

.app-masthead__colour-3 {
  --background-color: rgb(255, 106, 0);
}

.app-masthead__colour-4 {
  --background-color: rgb(255, 149, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-5 {
  --background-color: rgb(255, 191, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-6 {
  --background-color: rgb(255, 234, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-7 {
  --background-color: rgb(234, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-8 {
  --background-color: rgb(191, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-9 {
  --background-color: rgb(149, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-10 {
  --background-color: rgb(106, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-11 {
  --background-color: rgb(43, 255, 0);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-12 {
  --background-color: rgb(0, 255, 43);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-13 {
  --background-color: rgb(0, 255, 106);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-14 {
  --background-color: rgb(0, 255, 149);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-15 {
  --background-color: rgb(0, 255, 191);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-16 {
  --background-color: rgb(0, 255, 234);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-17 {
  --background-color: rgb(0, 234, 255);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-18 {
  --background-color: rgb(0, 191, 255);
  --gradient-light: rgba(0, 0, 0, 0.5);
  --gradient-dark: rgba(0, 0, 0, 0.8);
}

.app-masthead__colour-19 {
  --background-color: rgb(0, 149, 255);
}

.app-masthead__colour-20 {
  --background-color: rgb(0, 106, 255);
}

.app-masthead__colour-21 {
  --background-color: rgb(0, 21, 255);
}

.app-masthead__colour-22 {
  --background-color: rgb(121, 77, 255);
}

.app-masthead__colour-23 {
  --background-color: rgb(151, 77, 255);
}

.app-masthead__colour-24 {
  --background-color: rgb(170, 51, 255);
}

.app-masthead__colour-25 {
  --background-color: rgb(198, 26, 255);
}

.app-masthead__colour-26 {
  --background-color: rgb(255, 0, 234);
}

.app-masthead__colour-27 {
  --background-color: rgb(255, 0, 191);
}

.app-masthead__colour-28 {
  --background-color: rgb(255, 0, 149);
}

.app-masthead__colour-29 {
  --background-color: rgb(255, 0, 106);
}

.app-masthead__colour-30 {
  --background-color: rgb(255, 0, 64);
}

.app-masthead__colour-31 {
  --background-color: rgb(255, 0, 21);
}

.app-masthead--pastel {
  --gradient-light: rgba(255, 255, 255, 0.9);
  --gradient-dark: rgba(255, 255, 255, 0.75);
  --masthead-color: #000;
}

.app-masthead__colour-1--pastel {
  --background-color: rgb(255, 138, 128);
}

.app-masthead__colour-2--pastel {
  --background-color: rgb(255, 159, 128);
}

.app-masthead__colour-3--pastel {
  --background-color: rgb(255, 181, 128);
}

.app-masthead__colour-4--pastel {
  --background-color: rgb(255, 191, 102);
}

.app-masthead__colour-5--pastel {
  --background-color: rgb(255, 217, 102);
}

.app-masthead__colour-6--pastel {
  --background-color: rgb(255, 242, 102);
}

.app-masthead__colour-7--pastel {
  --background-color: rgb(242, 255, 102);
}

.app-masthead__colour-8--pastel {
  --background-color: rgb(223, 255, 128);
}

.app-masthead__colour-9--pastel {
  --background-color: rgb(202, 255, 128);
}

.app-masthead__colour-10--pastel {
  --background-color: rgb(181, 255, 128);
}

.app-masthead__colour-11--pastel {
  --background-color: rgb(170, 255, 153);
}

.app-masthead__colour-12--pastel {
  --background-color: rgb(153, 255, 170);
}

.app-masthead__colour-13--pastel {
  --background-color: rgb(153, 255, 195);
}

.app-masthead__colour-14--pastel {
  --background-color: rgb(153, 255, 204);
}

.app-masthead__colour-15--pastel {
  --background-color: rgb(153, 255, 229);
}

.app-masthead__colour-16--pastel {
  --background-color: rgb(153, 255, 246);
}

.app-masthead__colour-17--pastel {
  --background-color: rgb(153, 246, 255);
}

.app-masthead__colour-18--pastel {
  --background-color: rgb(153, 229, 255);
}

.app-masthead__colour-19--pastel {
  --background-color: rgb(153, 212, 255);
}

.app-masthead__colour-20--pastel {
  --background-color: rgb(153, 195, 255);
}

.app-masthead__colour-21--pastel {
  --background-color: rgb(178, 191, 255);
}

.app-masthead__colour-22--pastel {
  --background-color: rgb(178, 178, 255);
}

.app-masthead__colour-23--pastel {
  --background-color: rgb(198, 178, 255);
}

.app-masthead__colour-24--pastel {
  --background-color: rgb(210, 178, 255);
}

.app-masthead__colour-25--pastel {
  --background-color: rgb(223, 178, 255);
}

.app-masthead__colour-26--pastel {
  --background-color: rgb(236, 178, 255);
}

.app-masthead__colour-27--pastel {
  --background-color: rgb(255, 178, 249);
}

.app-masthead__colour-28--pastel {
  --background-color: rgb(255, 153, 229);
}

.app-masthead__colour-29--pastel {
  --background-color: rgb(255, 153, 212);
}

.app-masthead__colour-30--pastel {
  --background-color: rgb(255, 153, 195);
}

.app-masthead__colour-31--pastel {
  --background-color: rgb(255, 153, 161);
}

.app-masthead {
  position: relative;
  background: #0070a8;
  background: var(--background-color, #0070a8);
}

.app-masthead::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: radial-gradient(circle at top right, var(--gradient-light, rgba(0, 0, 0, 0.4)), var(--gradient-dark, rgba(0, 0, 0, 0.7)));
}

.app-masthead--pastel .app-masthead {
  background: rgb(102, 170, 204);
  background: var(--background-color, rgb(102, 170, 204));
}

.app-masthead.app-masthead--disable-journal-colour::after {
  background: unset;
}

@media only screen and (max-width: 479px) {
  .app-masthead::after {
    background: linear-gradient(225deg, var(--gradient-light, rgba(0, 0, 0, 0.4)), var(--gradient-dark, rgba(0, 0, 0, 0.7)));
  }
}
.app-masthead__container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
  position: relative;
  z-index: 1;
  color: var(--masthead-color, #fff);
}

.MathJax_SVG svg {
  width: auto;
  height: auto;
}

.MathJax_SVG svg {
  max-width: 100%;
}

#MathJax_Zoom svg {
  max-width: none;
}

.l-grid {
  --grid--gap: 1em;
  --grid--basis: min(278px, 100%);
}

/**
 * Utilities
 */
.u-clearfix::before, .u-clearfix::after {
  content: "";
  display: table;
}
.u-clearfix::after {
  clear: both;
}

.u-clear-both {
  clear: both;
}

.u-clear-left {
  clear: left;
}

.u-clear-right {
  clear: right;
}

.u-clear-float {
  float: none;
}

.u-color-open-access {
  color: #b74616;
}

.u-color-inherit {
  color: inherit;
}

.u-container {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 16px;
}

.u-display-block {
  display: block;
}

.u-display-inline {
  display: inline;
}

.u-display-inline-block {
  display: inline-block;
}

.u-display-flex {
  display: flex;
  width: 100%;
}

.u-display-inline-flex {
  display: inline-flex;
}

.u-display-grid {
  display: grid;
}

.u-display-table {
  display: table;
}

.u-flex-direction-row {
  flex-direction: row;
}

.u-flex-direction-column {
  flex-direction: column;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-align-items-center {
  align-items: center;
}

.u-align-items-baseline {
  align-items: baseline;
}

.u-justify-content-space-between {
  justify-content: space-between;
}

.u-justify-content-center {
  justify-content: center;
}

.u-justify-content-left {
  justify-content: left;
}

.u-flex-grow {
  flex: 1 0 auto;
}

.u-flex-grow-shrink {
  flex: 1 1 auto;
}

.u-flex-shrink {
  flex: 0 1 auto;
}

.u-flex-static {
  flex: 0 0 auto;
}

.u-align-self-center {
  align-self: center;
}

.u-align-self-start {
  align-self: flex-start;
}

.u-align-self-end {
  align-self: flex-end;
}

.u-float-right {
  float: right;
}

.u-float-left {
  float: left;
}

.u-display-none {
  display: none;
}

/* hide from both screenreaders and browsers */
.u-hide,
.js .u-js-hide {
  display: none;
  visibility: hidden;
}
.u-hide:first-child + *,
.js .u-js-hide:first-child + * {
  margin-block-start: 0;
}

/* show to both screenreaders and browsers */
.u-show,
.js .u-js-show {
  display: block;
  visibility: visible;
}

.u-show-inline,
.js .u-js-show-inline {
  display: inline;
  visibility: visible;
}

/* hide only visually, but have it available for screenreaders */
.u-visually-hidden,
.js .u-js-visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
.u-visually-hidden--off,
.js .u-js-visually-hidden--off {
  border: 0;
  clip: initial;
  height: auto;
  margin: 0;
  overflow: auto;
  padding: 0;
  position: relative;
  width: auto;
}

/* make invisible but retain dimensions */
.u-invisible {
  visibility: hidden;
}

/* hide only the text, keep element visible */
.u-hide-text,
.js .u-js-hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  direction: ltr;
  vertical-align: bottom;
}

/* hiding overflow */
.u-hide-overflow {
  overflow: hidden;
}

.u-hide-sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media print {
  .u-hide-print {
    display: none;
  }
}
/**
 * media-query hiding
 * intentionally avoids 'max' and 'range' to prevent bloat
 */
@media only screen and (min-width: 480px) {
  .u-hide-at-xs,
  .js .u-js-hide-at-xs {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xs:first-child + *,
  .js .u-js-hide-at-xs:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xs,
  .js .u-js-show-at-xs {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 768px) {
  .u-hide-at-sm,
  .js .u-js-hide-at-sm {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-sm:first-child + *,
  .js .u-js-hide-at-sm:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-sm,
  .js .u-js-show-at-sm {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 876px) {
  .u-hide-at-md,
  .js .u-js-hide-at-md {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-md:first-child + *,
  .js .u-js-hide-at-md:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-md,
  .js .u-js-show-at-md {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1024px) {
  .u-hide-at-lg,
  .js .u-js-hide-at-lg {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-lg:first-child + *,
  .js .u-js-hide-at-lg:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-lg,
  .js .u-js-show-at-lg {
    display: block;
    visibility: visible;
  }
}
@media only screen and (min-width: 1220px) {
  .u-hide-at-xl,
  .js .u-js-hide-at-xl {
    display: none;
    visibility: hidden;
  }
  .u-hide-at-xl:first-child + *,
  .js .u-js-hide-at-xl:first-child + * {
    margin-block-start: 0;
  }
  .u-show-at-xl,
  .js .u-js-show-at-xl {
    display: block;
    visibility: visible;
  }
}
.u-icon {
  fill: currentColor;
  transform: translate(0, 0);
  display: inline-block;
  vertical-align: text-top;
  width: 1em;
  height: 1em;
}

.u-full-height {
  height: 100%;
}

.u-vertical-align-baseline {
  vertical-align: baseline;
}

.u-vertical-align-top {
  vertical-align: top;
}

.u-vertical-align-bottom {
  vertical-align: bottom;
}

.u-vertical-align-middle {
  vertical-align: middle;
}

.u-link-text {
  font-size: 1rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

.u-link-text--large {
  font-size: 1.25rem;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

.u-list-style-number {
  list-style-type: decimal;
}

.u-list-style-bullet {
  list-style-type: disc;
}

.u-list-style-dash > ::marker {
  content: "– ";
  font-size: 1.2em;
  line-height: 1;
}

.u-list-style-none {
  list-style-type: none;
}

.u-list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.u-list-inline {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-inline > li {
  display: inline;
}

.u-custom-list-number {
  float: left;
  width: 3em;
  padding-right: 0.5em;
  margin-left: -3.5em;
  text-align: right;
}

.u-list-comma-separated {
  list-style: none;
  margin: 0;
  padding: 0;
}
.u-list-comma-separated > li {
  display: inline;
}
.u-list-comma-separated > li:not(:first-child):not(:last-child)::before {
  content: ", ";
}
.u-list-comma-separated > li:not(:only-child):last-child::before {
  content: " & ";
}

.u-list-truncated > li:not(:only-child):last-child::before {
  content: " ... ";
}

.u-overflow-hidden {
  overflow: hidden;
}

.u-overflow-visible {
  overflow: visible;
}

.u-position-absolute {
  position: absolute;
}

.u-position-relative {
  position: relative;
}

.u-position-top {
  top: 0;
}

.u-position-left {
  left: 0;
}

.u-position-bottom {
  bottom: 0;
}

.u-position-right {
  right: 0;
}

.u-position-negative-right {
  right: 100%;
}

.u-scrollbar-thin {
  scrollbar-width: thin;
}

.u-ma-0 {
  margin: 0;
}

.u-ma-2 {
  margin: 2px;
}

.u-ma-4 {
  margin: 4px;
}

.u-ma-8 {
  margin: 8px;
}

.u-ma-16 {
  margin: 16px;
}

.u-ma-24 {
  margin: 24px;
}

.u-ma-32 {
  margin: 32px;
}

.u-ma-48 {
  margin: 48px;
}

.u-ma-64 {
  margin: 64px;
}

.u-ma-auto {
  margin: auto;
}

.u-mt-0 {
  margin-top: 0;
}

.u-mt-2 {
  margin-top: 2px;
}

.u-mt-4 {
  margin-top: 4px;
}

.u-mt-8 {
  margin-top: 8px;
}

.u-mt-16 {
  margin-top: 16px;
}

.u-mt-24 {
  margin-top: 24px;
}

.u-mt-32 {
  margin-top: 32px;
}

.u-mt-48 {
  margin-top: 48px;
}

.u-mt-64 {
  margin-top: 64px;
}

.u-mt-auto {
  margin-top: auto;
}

.u-mr-0 {
  margin-right: 0;
}

.u-mr-2 {
  margin-right: 2px;
}

.u-mr-4 {
  margin-right: 4px;
}

.u-mr-8 {
  margin-right: 8px;
}

.u-mr-16 {
  margin-right: 16px;
}

.u-mr-24 {
  margin-right: 24px;
}

.u-mr-32 {
  margin-right: 32px;
}

.u-mr-48 {
  margin-right: 48px;
}

.u-mr-64 {
  margin-right: 64px;
}

.u-mr-auto {
  margin-right: auto;
}

.u-mb-0 {
  margin-bottom: 0;
}

.u-mb-2 {
  margin-bottom: 2px;
}

.u-mb-4 {
  margin-bottom: 4px;
}

.u-mb-8 {
  margin-bottom: 8px;
}

.u-mb-16 {
  margin-bottom: 16px;
}

.u-mb-24 {
  margin-bottom: 24px;
}

.u-mb-32 {
  margin-bottom: 32px;
}

.u-mb-48 {
  margin-bottom: 48px;
}

.u-mb-64 {
  margin-bottom: 64px;
}

.u-mb-auto {
  margin-bottom: auto;
}

.u-ml-0 {
  margin-left: 0;
}

.u-ml-2 {
  margin-left: 2px;
}

.u-ml-4 {
  margin-left: 4px;
}

.u-ml-8 {
  margin-left: 8px;
}

.u-ml-16 {
  margin-left: 16px;
}

.u-ml-24 {
  margin-left: 24px;
}

.u-ml-32 {
  margin-left: 32px;
}

.u-ml-48 {
  margin-left: 48px;
}

.u-ml-64 {
  margin-left: 64px;
}

.u-ml-auto {
  margin-left: auto;
}

.u-pa-0 {
  padding: 0;
}

.u-pa-2 {
  padding: 2px;
}

.u-pa-4 {
  padding: 4px;
}

.u-pa-8 {
  padding: 8px;
}

.u-pa-16 {
  padding: 16px;
}

.u-pa-24 {
  padding: 24px;
}

.u-pa-32 {
  padding: 32px;
}

.u-pa-48 {
  padding: 48px;
}

.u-pa-64 {
  padding: 64px;
}

.u-pt-0 {
  padding-top: 0;
}

.u-pt-2 {
  padding-top: 2px;
}

.u-pt-4 {
  padding-top: 4px;
}

.u-pt-8 {
  padding-top: 8px;
}

.u-pt-16 {
  padding-top: 16px;
}

.u-pt-24 {
  padding-top: 24px;
}

.u-pt-32 {
  padding-top: 32px;
}

.u-pt-48 {
  padding-top: 48px;
}

.u-pt-64 {
  padding-top: 64px;
}

.u-pr-0 {
  padding-right: 0;
}

.u-pr-2 {
  padding-right: 2px;
}

.u-pr-4 {
  padding-right: 4px;
}

.u-pr-8 {
  padding-right: 8px;
}

.u-pr-16 {
  padding-right: 16px;
}

.u-pr-24 {
  padding-right: 24px;
}

.u-pr-32 {
  padding-right: 32px;
}

.u-pr-48 {
  padding-right: 48px;
}

.u-pr-64 {
  padding-right: 64px;
}

.u-pb-0 {
  padding-bottom: 0;
}

.u-pb-2 {
  padding-bottom: 2px;
}

.u-pb-4 {
  padding-bottom: 4px;
}

.u-pb-8 {
  padding-bottom: 8px;
}

.u-pb-16 {
  padding-bottom: 16px;
}

.u-pb-24 {
  padding-bottom: 24px;
}

.u-pb-32 {
  padding-bottom: 32px;
}

.u-pb-48 {
  padding-bottom: 48px;
}

.u-pb-64 {
  padding-bottom: 64px;
}

.u-pl-0 {
  padding-left: 0;
}

.u-pl-2 {
  padding-left: 2px;
}

.u-pl-4 {
  padding-left: 4px;
}

.u-pl-8 {
  padding-left: 8px;
}

.u-pl-16 {
  padding-left: 16px;
}

.u-pl-24 {
  padding-left: 24px;
}

.u-pl-32 {
  padding-left: 32px;
}

.u-pl-48 {
  padding-left: 48px;
}

.u-pl-64 {
  padding-left: 64px;
}

.u-keyline {
  border-bottom: 1px solid #999999;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.u-keyline--thick {
  border-bottom: 2px solid #999999;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.u-shadow {
  border: 1px solid #000000;
  box-shadow: 5px 5px 5px #000000;
}

.u-button-reset {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.u-monospace {
  font-family: monospace;
}

.u-sans-serif {
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.u-serif {
  font-family: "Merriweather", serif;
}

.u-small-caps {
  font-size: 0.8em; /* scale based on containing text */
  text-transform: uppercase;
}

.u-text-right {
  text-align: right;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-normal {
  font-weight: 400;
}

.u-text-bold {
  font-weight: 700;
}

.u-text-xxs {
  font-size: 0.75rem;
}

.u-text-xs {
  font-size: 0.875rem;
}

.u-text-s {
  font-size: 1rem;
}

.u-text-default {
  font-size: 1.125rem;
}

.u-text-md {
  font-size: 1.25rem;
}

.u-text-l {
  font-size: 1.5rem;
}

.u-text-xl {
  font-size: 1.75rem;
}

.u-text-xxl {
  font-size: 2rem;
}

.u-text-hl {
  font-size: 2.1875rem;
}

.u-text-hxl {
  font-size: 2.25rem;
}

.u-meta-large {
  font-weight: 400;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
}

.u-meta-medium {
  font-weight: 400;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
}

.u-meta-small {
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Merriweather Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.u-double-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
  border-bottom: 1px solid;
}

.u-text-font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.u-word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.u-upper {
  text-transform: uppercase;
}

.u-lower {
  text-transform: lowercase;
}

.u-capitalize {
  text-transform: capitalize;
}

.u-underline {
  text-decoration: underline;
  -webkit-text-decoration-skip: skip;
  text-decoration-skip-ink: auto;
}

.u-unstyled {
  font-weight: normal;
  font-style: normal;
}

.u-h1 {
  font-size: 2.25rem;
  font-size: clamp(1.75rem, 4vw, 2.25rem);
  font-weight: 700;
  line-height: 1.2;
}

.u-h2 {
  font-size: 1.75rem;
  font-size: clamp(1.5rem, 4vw, 1.75rem);
  font-weight: 700;
  line-height: 1.2;
}

.u-h3 {
  font-size: 1.5rem;
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-weight: 700;
  line-height: 1.2;
}

.u-h4 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
}

.u-h5 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2;
}

.u-mbs-0 {
  margin-block-start: 0 !important;
}

/**
 * Overrides
 */
.u-link-inherit {
  color: inherit;
}
.u-link-inherit.visited, .u-link-inherit:visited {
  color: inherit;
}
.u-link-inherit.hover, .u-link-inherit:hover {
  color: inherit;
}

.u-link-faux-block::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.c-account-nav__anchor.is-open.eds-c-header__link::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzAyNWU4ZCIgaWQ9ImVkcy1pLXVzZXItc2luZ2xlLW1lZGl1bSI+PHBhdGggZD0iTTEyIDFhNSA1IDAgMSAxIDAgMTAgNSA1IDAgMCAxIDAtMTBabTAgMmEzIDMgMCAxIDAgMCA2IDMgMyAwIDAgMCAwLTZabS0uNDA2IDkuMDA4YTguOTY1IDguOTY1IDAgMCAxIDYuNTk2IDIuNDk0QTkuMTYxIDkuMTYxIDAgMCAxIDIxIDIxLjAyNVYyMmExIDEgMCAwIDEtMSAxSDRhMSAxIDAgMCAxLTEtMXYtLjk4NWMuMDUtNC44MjUgMy44MTUtOC43NzcgOC41OTQtOS4wMDdabS4zOSAxLjk5Mi0uMjk5LjAwNmMtMy42My4xNzUtNi41MTggMy4xMjctNi42NzggNi43NzVMNSAyMWgxMy45OThsLS4wMDktLjI2OGE3LjE1NyA3LjE1NyAwIDAgMC0xLjk3LTQuNTczbC0uMjE0LS4yMTNBNi45NjcgNi45NjcgMCAwIDAgMTEuOTg0IDE0WiIvPjwvc3ZnPgo=");
}

.app-mailto-link {
  font-size: 1rem;
  padding-top: 16px;
  text-align: center;
}
@media only screen and (min-width: 480px) {
  .app-mailto-link {
    text-align: right;
  }
}

.eds-c-card__metadata {
  flex: 0 0 200px;
}

.app-card-height li > * {
  height: 100%;
}