.app-homepage-about {
	@include media-query('sm') {
		columns: 2;
		column-gap: spacing(64);
	}

	> p {
		font-size: $t-a-typography-font-size-s;
		margin-top: 0;

		@include media-query('sm') {
			margin-bottom: 0;
		}
	}
}
