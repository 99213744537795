$homepage-brands-font-size: $t-a-typography-font-size-default;
$homepage-brand-border-color: #cedbe0; //$t-color-blue-200

.app-homepage-brands {
	@include u-container;
	margin-bottom: spacing(32);
}

@include from-desktop {
	.app-homepage-brands {
		margin-bottom: spacing(64);
	}
}

.app-homepage-brands__heading {
	@include u-h5;
}

.app-homepage-brands__list {
	@include u-list-reset;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	text-align: center;
	border-top: 1px solid $homepage-brand-border-color;
	border-bottom: 1px solid $homepage-brand-border-color;
	padding-top: spacing(24);
	padding-bottom: spacing(24);

	li {
		flex: 1 1 50%;
		margin: 0 0 spacing(48);

		&:not(:last-child) {
			padding-right: spacing(24);
		}

		&:nth-last-child(1),
		&:nth-last-child(2) {
			margin: 0;
		}
	}
}

@include from-tablet {
	.app-homepage-brands__list {
		li {
			flex: 1 1 33%;

			&:nth-last-child(3) {
				margin: 0;
			}
		}
	}
}

@include from-desktop {
	.app-homepage-brands__list {
		flex-wrap: wrap;
		text-align: left;

		li {
			flex: none;
			margin: 0;
		}
	}
}
