$homepage-featured-books-card--border-width-style: $homepage-card--border-width-style;
$homepage-featured-books-card--border-color: $homepage-card--border-color;
$homepage-featured-books-card--border-radius: $homepage-card--border-radius;
$homepage-featured-books-card--content-font-size: $homepage-card--content-font-size;
$homepage-featured-books-card--min-image-width: 142px;
$homepage-featured-books-card--background-color: $t-a-palette-default-blue-lighter;

.app-homepage-featured-books__list {
	@include u-list-reset;
	display: grid;
	gap: spacing(8);
	grid-template-columns: repeat(2, minmax($homepage-featured-books-card--min-image-width, 50%));
	justify-content: space-evenly;

	> li {
		border: $homepage-featured-books-card--border-width-style $homepage-featured-books-card--border-color;
		border-radius: $homepage-featured-books-card--border-radius;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		position: relative;
		padding-bottom: spacing(16);
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.app-homepage-featured-books__cover {
	min-height: 265px;
	background-color: $homepage-featured-books-card--background-color;
}

.app-homepage-featured-books__content {
	line-height: 1.2;
	padding: spacing(8);
}

.app-homepage-featured-books__link {
	@include u-link-faux-block;
	font-size: $homepage-featured-books-card--content-font-size;
	font-weight: $context--font-weight-bold;
	color: inherit;

	&:hover,
	&:visited {
		color: inherit;
	}
}

@include from-tablet {
	.app-homepage-featured-books__list {
		grid-template-columns: repeat(auto-fit, $homepage-featured-books-card--min-image-width);
	}

	.app-homepage-featured-books__cover {
		min-height: 220px;
	}
}
