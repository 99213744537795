$layout-section--background-height: 332px;
$layout-section--dominant-background-color-1: #ab2500;
$layout-section--dominant-background-color-2: #014425;
$layout-section--dominant-background-color-3: #1e1439;
$layout-section--dominant-background-color-4: #f0f7fc;
$layout-section--padding: spacing(24);
$layout-section--padding-tablet: spacing(48);

.app-layout-section {
	min-height: $layout-section--background-height;
	position: relative;
}

.app-layout-section--no-background {
	min-height: 0;
}

.app-layout-section__background {
	position: absolute;
	z-index: -1;
	width: 100%;
	height: $layout-section--background-height;
	object-fit: cover;
	object-position: left;
}

.app-layout-section__background--1 {
	background-color: $layout-section--dominant-background-color-1;
}

.app-layout-section__background--2 {
	background-color: $layout-section--dominant-background-color-2;
}

.app-layout-section__background--3 {
	background-color: $layout-section--dominant-background-color-3;
}

.app-layout-section__background--4 {
	background-color: $layout-section--dominant-background-color-4;
	min-height: auto;
	margin-bottom: 0;
}

.app-layout-section__container {
	@include u-container;
	padding-top: spacing(24);
	padding-bottom: $layout-section--padding-tablet;
}

.app-layout-section__container.app-layout-section__container--no-padding-start-end {
	padding-top: 0;
	padding-bottom: 0;
}

@include from-desktop {
	.app-layout-section__container {
		padding-top: $layout-section--padding-tablet;
	}
}

.app-layout-section__heading {
	font-weight: bold;
	margin-top: 0;
	margin-bottom: spacing(4);
}

@include from-desktop {
	.app-layout-section__line-break {
		display: block;
	}
}

.app-layout-section__heading--light {
	color: white;
	text-shadow: 0 0 4px black;
}

.app-layout-section__link {
	@include u-link-inherit;
}

.app-layout-section__text {
	font-size: 1rem;
	line-height: 1.5;
}

.app-layout-section__heading--dark {
	color: #000;
}

.app-layout-section__text--light {
	font-size: 1rem;
	color: white;
	text-shadow: 0 0 4px black;
}

.app-layout-section__heading-layout {
	display: flex;
	align-items: center;
	margin-bottom: spacing(24);
}

.app-layout-section__icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: flex-start;
	width: 48px;
	height: 48px;
	background-color: #f0f7fc;
	border-radius: 50%;
	color: #222;
	font-size: 24px;

	+ * {
		padding-left: spacing(8);
		flex: 0 999 auto;
	}
}

@include from-tablet {
	.app-layout-section__icon-container {
		font-size: 32px;
		width: 64px;
		height: 64px;

		img {
			width: auto;
		}

		+ * {
			padding-left: spacing(16);
		}
	}
}

.app-layout-section__icon-container--light {
	background-color: #fff;
}

.app-layout-section__container--padding-top {
	padding-top: spacing(32);
}
