// These styles are used by SNL homepage, SNL search pages, Subject pages and by Brand Hubs pages
.app-layout-two-column {
	display: flex;
	align-content: flex-start;
	word-wrap: break-word;
	overflow-wrap: anywhere;
	flex-direction: column;
}

.app-layout-two-column > :not(.app-layout-two-column__sidebar) {
	flex-basis: 0;
	flex-grow: 999;
}

.app-layout-two-column__sidebar {
	flex-basis: 100%;
	width: 100%;

	&:empty {
		margin-bottom: 0;
	}
}

@include from-desktop {
	.app-layout-two-column {
		flex-direction: row;
		justify-content: space-between;
	}

	.app-layout-two-column__main-content {
		min-width: 0;
	}

	.app-layout-two-column__sidebar {
		flex-grow: 1;
		flex-basis: 300px;
	}

	.app-layout-two-column .app-layout-two-column__sidebar {
		flex-grow: initial;
	}

	.app-layout-two-column > :first-child {
		margin-right: 30px;
	}
}
