$homepage-card--background-color: #fff;
$homepage-card--border-radius: 8px;
$homepage-card--border-width-style: 1px solid;
$homepage-card--border-color: $t-a-palette-default-blue-light;
$homepage-card--default--title-color: color('black');
$homepage-card--content-font-size: $t-a-typography-font-size-xs;
$homepage-card--content-line-height: 1.7;
$homepage-card--font-family: $t-a-typography-font-family-default;
$homepage-card--heading-font-size-default: $t-a-typography-font-size-default;
$homepage-card--heading-font-size-s: $t-a-typography-font-size-s;
$homepage-card--heading-link-line-height: $t-a-typography-line-height-md;
$homepage-card--heading-margin-bottom: spacing(16);
$homepage-card--spacing: spacing(16);
$homepage-card--cover-image-max-width: 73px;

.app-homepage-card {
	display: flex;
	position: relative;
	padding: $homepage-card--spacing;
	background-color: $homepage-card--background-color;
	border: $homepage-card--border-width-style $homepage-card--border-color;
	border-radius: $homepage-card--border-radius;
	overflow: hidden;
	justify-content: space-between;
	font-family: $homepage-card--font-family;
}

.app-homepage-card--stack {
	flex-direction: column;
}

.app-homepage-card--mutant {
	// makes portrait card look like card-open at xl - take this out of app-homepage-card?
	flex: 1 1 auto;
	flex-direction: row-reverse;
	border: none;
	border-top: 1px solid $t-a-palette-default-blue-light;
	padding: spacing(24) 0 spacing(8);
	margin: 0;
	border-radius: 0;

	@include media-query('xl') {
		flex-direction: column;
		padding: spacing(16);
		border: 1px solid $t-a-palette-default-blue-light;
		border-radius: $homepage-card--border-radius;
		flex: 1 1 262px;
	}

	.app-homepage-card__main {
		margin-right: spacing(16);

		@include media-query('xl') {
			margin-right: 0;
		}
	}

	.app-homepage-card__side {
		flex: 0 0 auto;
		max-width: 114px;
		width: 100%;

		img {
			border: 1px solid #cfd8dc;
			border-radius: 12px;
		}


		@include media-query('xl') {
			border: none;
			max-width: none;
			width: auto;
		}

		@media (min-width: 640px) {
			// media query taken from card-open picture size attr value
			max-width: 192px;
		}

		@include media-query('xl') {
			max-width: none;
		}
	}

	.app-homepage-card__side--flush {
		margin: 0;

		@include media-query('xl') {
			margin: -16px -16px $homepage-card--spacing -16px;
		}
	}
}

.app-homepage-card__main {
	display: flex;
	flex: 1 1 auto;
	word-break: break-word;
	font-size: $homepage-card--content-font-size;
}

.app-homepage-card__side {
	flex: 0 1 auto;
}

.app-homepage-card__side--flush {
	margin: -16px -16px $homepage-card--spacing -16px;
}

.app-homepage-card__content {
	display: flex;
	flex-direction: column;
	font-weight: $t-a-typography-font-weight-normal;
}

.app-homepage-card__content--row {
	flex-direction: row;

	> *:first-child {
		align-self: center;
		padding-right: spacing(16);
		text-decoration: none;
		line-height: 1.5;

		&:hover {
			text-decoration: underline;
			text-decoration-thickness: $t-a-link-thickness-hover;
			text-underline-offset: $t-a-link-offset-hover;
		}
	}
}

.app-homepage-card__content--padding {
	padding: spacing(16) 0;
}

.app-homepage-card__title-link {
	@include u-link-faux-block;
	line-height: 1.4;
	color: currentColor;

	&:hover,
	&:visited {
		color: currentColor;
	}
}

.app-homepage-card__text {
	line-height: $homepage-card--content-line-height;
	position: relative;

	> div:first-child {
		margin-bottom: 4px;
	}

	p {
		margin: 0;
	}

	ul {
		margin: 0;
	}
}

.app-homepage-card__heading {
	margin-top: 0;
	margin-bottom: $homepage-card--heading-margin-bottom;
	font-size: $homepage-card--heading-font-size-default;

	a {
		color: $homepage-card--default--title-color;
		line-height: $homepage-card--heading-link-line-height;
	}
}

.app-homepage-card img {
	width: 100%;
}

.app-homepage-card__cover-image {
	flex: 0 0 auto;

	img {
		border: 1px solid white;
		max-width: $homepage-card--cover-image-max-width;
	}
}

.app-homepage-card__icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 42px;
	height: 42px;
	background-color: #eff6fb;
	border-radius: 50%;

	img {
		width: 60%;
	}

	+ * {
		padding-left: spacing(16);
	}
}

@include from-tablet {
	.app-homepage-card__icon-container {
		width: 62px;
		height: 62px;

		img {
			width: auto;
		}
	}
}

.app-homepage-card__description-list {
	padding-top: spacing(16);
	margin-block-start: 0;
	margin-block-end: 0;

	> div {
		display: flex;
		justify-content: space-between;

		&:not(:last-child) {
			border-bottom: $homepage-card--border-width-style $homepage-card--border-color;
			margin-bottom: spacing(8);
			padding-bottom: spacing(8);
		}
	}

	dd {
		font-weight: $context--font-weight-bold;
	}
}

.app-homepage-card__link {
	@include u-link-inherit;
	font-weight: bold;
}

.app-homepage-card__link--faux {
	@include u-link-faux-block;
}

@include from-tablet {
	.app-homepage-card__content {
		flex-direction: column;
		justify-content: space-between;
	}

	.app-homepage-card__content--row {
		flex-direction: row;
		justify-content: flex-start;

		> *:first-child {
			align-self: flex-start;
		}
	}

	.app-homepage-card__text--flex {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.app-homepage-card__description {
		flex-grow: 1;
	}
}
