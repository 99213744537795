// potential additions to app-card-highlight - held here as homepage is not using associated template [RL]
// There's some tech debt in here. To honour the design from mobile to desktop there are overrides in this file which
// adjusts the layout within the necessary breakpoints but the css is unaltered from the elements/app-card-highlight component [MS]

.app-card-highlight {
	flex-direction: row;
	border-left: $card-highlight--highlight-border-width-style $card-highlight--border-color;
	border-top: none;
	padding: spacing(24) spacing(8);
	position: relative;
}

.app-card-highlight__content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.app-card-highlight .app-card-highlight__side {
	flex: 0 0 auto;
	align-self: center;
	margin: -24px -8px -24px spacing(8);
	max-width: 55%;
}

@include from-tablet {
	.app-card-highlight .app-card-highlight__side {
		max-width: 30%;
	}
}

.app-card-highlight__heading {
	margin-bottom: 0;
	font-size: $t-a-typography-font-size-s;

	a {
		@include u-link-faux-block;
		text-decoration: none;
		line-height: 1.2;

		&:hover {
			text-decoration: underline;
			text-decoration-thickness: $t-a-link-thickness-hover;
			text-underline-offset: $t-a-link-offset-hover;
		}
	}
}

.app-card-highlight__heading--secondary {
	font-size: $t-a-typography-font-size-xs;
}

.app-card-highlight img {
	border-radius: 0 12px 12px 0;
	max-width: 258px;
}

.app-card-highlight__side {
	margin-bottom: 0;
}

.app-card-highlight--normal-order-stack .app-card-highlight__side {
	margin: -24px 0 0 -24px;
}

@include from-desktop {
	.app-card-highlight {
		padding: spacing(24);
	}

	.app-card-highlight--normal-order-stack {
		flex-direction: column;
		border-left: none;
		border-top: $card-highlight--highlight-border-width-style $card-highlight--border-color;
	}

	.app-card-highlight--normal-order-stack .app-card-highlight__side {
		align-self: start;
		margin: 0;
	}

	.app-card-highlight--stack {
		flex-direction: column-reverse;
		border-left: none;
		border-top: $card-highlight--highlight-border-width-style $card-highlight--border-color;
	}

	.app-card-highlight__content {
		display: block;
		flex-direction: unset;
		justify-content: unset;
	}

	.app-card-highlight--stack .app-card-highlight__side {
		max-width: none;
		margin: -24px -24px 0 -24px;
	}

	.app-card-highlight--stack .app-card-highlight__heading {
		margin-top: spacing(24);
		margin-bottom: $card-highlight--heading-margin-bottom;
		font-size: $t-a-typography-font-size-l;
	}

	.app-card-highlight__heading--secondary {
		font-size: $t-a-typography-font-size-md;
	}

	.app-card-highlight--stack img {
		max-width: initial;
		border-radius: initial;
	}
}
