// trending research

.app-homepage-trending {
	display: flex;
	flex-wrap: wrap;
	margin-right: -16px;

	@include media-query('sm') {
		flex-direction: row;
	}

	> * {
		flex: 1 1 auto;
		margin-right: spacing(16);
		margin-bottom: spacing(16);

		@include media-query('sm') {
			flex: 1 1 315px;
		}

		@include media-query('md') {
			flex: 1 1 320px;
		}

		@include media-query('lg') {
			flex: 1 1 267px;
		}

		@include media-query('xl') {
			flex: 1 1 261px;
		}
	}
}
